<template>
  <v-row justify="center">
    <v-col md="12" cols="12" align="center" 
        >
        <draggable
        class="list-group"
        tag="ul"
        v-model="list"
        v-bind="dragOptions"
        @start="startDrag()"
        @end="endDrag()"
      >
        <transition-group type="transition" name="flip-list">
          <li
            class="list-group-item"
            v-for="element in list"
            :key="element.order"
          >
            <i
              :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
              @click="element.fixed = !element.fixed"
              aria-hidden="true"
            ></i>            
            {{ element.name }}
          </li>
        </transition-group>
      </draggable>
    </v-col>
  </v-row>
</template>
<script>
import draggable from 'vuedraggable'
import Vue from 'vue'

export default {
    name:"sortsentencequiz",
    components: {
            draggable,
    },
    props:[
        "question",
        "userResponses",
        "questionIndex",
        "words"
    ],
    data() {
        return {
            list: this.words.map((name, index) => {
                return { name, order: index + 1 }
            }),
            isDragging: false
        };
    },
    created(){
      if (this.question.hasOwnProperty("matchtemp")) {
            let matchtemp = this.question.matchtemp

            if (matchtemp.length > 0) {
                this.list = matchtemp
            }
      }
    },    
    methods: {
      sort() {
        this.list = this.list.sort((a, b) => a.order - b.order)
        
      },
      startDrag(){
        this.isDragging = true
      },
      endDrag() {
        this.isDragging = false
        Vue.set(this.question,"matchtemp", this.list)
      }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    }
}
</script>
<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  
  list-style-type: none;
  margin: 0px;
  padding: 5px;
  background-color: #bae1f5;
  border: solid 1px #82b5ce;
  border-radius: 3px;
  min-height: 44px;  
  
  
}
.list-group-item {
  cursor: move;  
  display: inline-block;
  height: 35px;
  width: auto;
  border: 2px dotted #aaa;
  background-color: white;
  padding: 5px;
  margin: 3px;
}
.list-group-item i {
  cursor: pointer;
}
</style>