import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import Blank from '@/pages/Blank';
import CalendarDetail from '@/components/CalendarDetail';
import AssessmentForm from '@/pages/teachers/AssessmentForm';
import DoHomeWork from '@/pages/students/DoHomeWork';
import route from "@/utils/route";

Vue.use(Router);

export default new Router({
  base: __dirname,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/login', component: Login, name: 'Login', beforeEnter: route.beforeLogin },
    { path: '/blank', component: Blank, name: 'Blank', title:"", beforeEnter:route.requireAuth },
    { path: '/', redirect: '/blank', name:"Default", beforeEnter: route.requireAuth },
    { 
      path: '/calendar/:id', component: CalendarDetail, name: 'CalendarDetail', beforeEnter: route.requireAuth,
      meta: { title: "Nội dung buổi học" }
    },
    { 
      path: '/students/dohomework/:id', component: DoHomeWork, name: 'DoHomeWork', beforeEnter: route.requireAuth,
      meta: { title: "Làm bài tập" }
    },
    {
      path: "/teachers/addassessment", 
      component: AssessmentForm,
      name: "AddAssessment",
      beforeEnter: route.requireAuth,
      meta: {
        title : "ASSESSMENT"
      }
    },
    {
      path: "/teachers/editassessment/:id", 
      component: AssessmentForm,
      name: "EditAssessment",
      beforeEnter: route.requireAuth,
      meta: {
        title : "ASSESSMENT"
      }
    },
  ],
  meta: {
    progress: {
      func: [
        {call: 'color', modifier: 'temp', argument: '#ffb000'},
        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
        {call: 'location', modifier: 'temp', argument: 'top'},
        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
      ]
    }
  }
});
