import Quiz from '@/pages/games/Quiz';
import Calendar from '@/components/Calendar';
import TeacherCommment from '@/pages/teachers/Comment';
import Assessment from '@/pages/teachers/Assessment';
import TeacherHomeWork from '@/pages/teachers/HomeWork';
import StudentHomeWork from '@/pages/students/HomeWork';
import ResultCourse from '@/pages/customers/ResultCourse';
import StudentResult from '@/pages/students/ResultLession';
import StudentResultCourse from '@/pages/students/StudentResultCourse';
import CustomerResult from '@/pages/customers/ResultLession';
import auth from '@/utils/auth';
import Store from '@/store';

export default {
    requireAuth(to, from, next){
        if (!auth.loggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
            Store.dispatch('user/updateMenuName', to.meta.title)
        }
    },
    beforeLogin(to,from, next) {
        if (auth.loggedIn()){
          next({path: from.path})
        }
        else {
          next()
        }
    },
    addRoutes(router,items) {

        for(let i=0;i<items.length;i++){
            let obj = items[i]

            
            let cp = obj.component

            if (cp == "Quiz"){                
                cp = Quiz
            }
            else if (cp == "Calendar"){
                cp = Calendar
            }     
            else if (cp == "CustomerResult"){
                cp = CustomerResult
            }
            else if (cp == "StudentResult"){
                cp = StudentResult
            }
            else if (cp == "TeacherCommment"){
                cp = TeacherCommment
            }
            else if (cp == "TeacherHomeWork"){
                cp = TeacherHomeWork
            }
            else if (cp == "StudentHomeWork"){
                cp = StudentHomeWork
            }
            else if (cp == "Assessment"){
                cp = Assessment
            }
            else if (cp == "ResultCourse"){
                cp = ResultCourse
            }
            else if (cp == "StudentResultCourse"){
                cp = StudentResultCourse
            }

            let route = {
                name : obj.link,
                path : obj.path,
                component: cp,
                title : obj.title,
                meta: { title: obj.title },
                beforeEnter: this.requireAuth
            }

            router.addRoute(route)
        }
        
    },
    clearRoutes(router){
        let routes = router.options.routes

        for(let i=0;i<routes.length;i++){
            let item = routes[i]

            let name = item.name

            if (name == "Login" || name == "Blank" || name == "Default" || name == "CalendarDetail"
                    || name == "AddAssessment" || name == "EditAssessment" || name == "DoHomeWork"){
                continue
            }

            router.removeRoute(name)
        }
    }

}