<template>
  <v-dialog v-model="dlgConfirm" persistent max-width="320">
    <v-card>
      <v-card-title>{{ dlgConfirmTitle }}</v-card-title>
      <v-card-text><div class="text-center">{{ dlgConfirmText }}</div></v-card-text>
      <v-card-actions class="justify-end">
          <div class="text-right">
              <v-spacer></v-spacer>
              <v-btn
                class="green--text darken-1"
                text="text"
                @click.native="$emit('onConfirm',dlgItem)"
                >Có</v-btn
                >
                <v-btn
                class="orange--text darken-1"
                text="text"
                @click.native="$emit('onCancel')"
                >Không</v-btn
                >
          </div>
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    props:["dlgConfirmTitle","dlgConfirmText","dlgConfirm","dlgItem"]
}
</script>