import api from "@/utils/backend-api";
import auth from "@/utils/auth";

const state = {
    events:[],
    contents:[]
};

const actions = {
    getEvents ({ commit }, obj) {
        var postData = {
            command:"AM00020",
            parameter:{
                ma_dvcs:"VP",
                loai:"Other",
                user_login:auth.getUserName(),
                tu_ngay: obj.tu_ngay,
                den_ngay: obj.den_ngay
            }
        };

        api.postData("/System/ExecuteCommand",postData).then(res => {
            commit("setEvents", res.data);
        });
    },
    getContents ({ commit },id) {
        var postData = {
            command:"AM00021",
            parameter:{
                hp_lichhoc_id:id
            }
        };

        api.postData("/System/ExecuteCommand",postData).then(res => {
            commit("setContents", res.data);
        });
    },
};

const mutations = {
  setEvents (state, events) {
    state.events = events;
  },
  setContents (state, contents) {
    state.contents = contents;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
}