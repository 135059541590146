<template>
  <v-row justify="center">
    <v-col md="12" cols="12" align="center">
        <p>{{question.content}}</p>
        <p>
            <template                    
                    v-for="se in sentens" >
                <template v-if="se.type == 'text'">{{se.name}}</template>
                <template v-if="se.type == 'input'" >
                     <input class="write" type="text" :ref="'ref_input_' + se.order" @keyup="inputKeyUp(templist[se.order],se.order)" :key="se.order" v-model="templist[se.order]" />
                </template>
            </template>
        </p>
        <canvas ref="hdchar" style="width:0px;height:0px;">
            
        </canvas>
    </v-col>
  </v-row>  
</template>
<script>
import Vue from 'vue'

export default {
    name:"writequiz",
    props:[
        "question",
        "userResponses",
        "questionIndex",
        "sentences"
    ],    
    data() {
        return {
            sentens: [],
            templist:{},
            hidenCharacter:""
        };
    },
    created(){
      let parts = this.sentences.split("_")

      let order = 0

      for(let i=0;i<parts.length;i++){

          let part = parts[i]
          
          order = order + 1

          let textItem = {
              name : part,
              order : order,
              type : "text",
              class : ""
          }

          this.sentens.push(textItem)

          if (i == (parts.length - 1) && part == "") {
              continue
          }
          else {
                order = order + 1

                let spanItem = {
                    name : "",
                    order : order,
                    type : "input",
                    class : ""
                }

                this.templist[order] = []
                this.sentens.push(spanItem)
          }         
      }
      
      if (this.question.hasOwnProperty("matchtemp")) {
            let matchtemp = this.question.matchtemp            
            this.templist = matchtemp.templist            
      }

    },
    mounted(){
        let parts = Object.keys(this.templist)

        for(let i=0;i<parts.length;i++) {
            let key = parts[i]

            let value = this.templist[key]

            if (value != "") {
                
                this.hidenCharacter = value

                let width = this.getWidth()               

                let [input] = this.$refs['ref_input_' + key]

                input.style.width = width + "px"
            }

        }
    },
    methods: {
      selectOptions(index){
        Vue.set(this.userResponses, this.questionIndex, index)
      },
      inputKeyUp(el,order) {
          this.hidenCharacter = el

          let width = this.getWidth()

          if (el == "") {
              width = 25
          }

          let [input] = this.$refs['ref_input_' + order]

          input.style.width = width + "px"

          this.endKeyUp()
      },
      getWidth(){
          let hdchar = this.$refs['hdchar']

          let context = hdchar.getContext("2d")
          context.font = "bold 12pt arial"

          let metrics = context.measureText(this.hidenCharacter)          

          return metrics.width + 10
      },
      endKeyUp() {        

        let matchtemp = {          
          templist : this.templist,
          sentences : this.getSentence()
        }

        Vue.delete(this.question,"matchtemp")
        Vue.set(this.question,"matchtemp", matchtemp)
      },
      getSentence(){
        let sentences = "";

        for(let i=0;i<this.sentens.length;i++){
          let item = this.sentens[i]

          if (item.type == "text"){
            sentences = sentences + item.name
          }
          else {
            if (this.templist.hasOwnProperty(item.order)){
              let temp = this.templist[item.order]
              
              sentences = sentences + temp.trim()              
            }
          }
        }

        return sentences
      }
    }
}
</script>
<style scoped>
.write {
    background-color: #FFF7DA;
    min-width: 25px;
    border-radius: 0px;    
    border: grey solid 1px;
    width: 25px;
    padding-left: 5px;
    padding-right: 5px;
}
.write:active,.write:focus {
    border-radius: 0px;
}
.write:focus-visible {
  outline: none;
}
.hidden_char {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap; /* Thanks to Herb Caudill comment */
}
</style>