<template>
    <v-layout fill-height style="background-color:white" class="elevation-5">
        
        <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
                style="height:100%"
                fill-height
            >
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        
                        class="mr-2"
                        color="blue darken-2"
                        @click="download(item)"
                    >
                        mdi-cloud-download
                    </v-icon>
                    
                </template>
            </v-data-table>      
    </v-layout>  
</template>
<script>
import api from "@/utils/backend-api";
import auth from "@/utils/auth";

export default {
    data () {
        return {
            headers: [
                {
                    text:"Ngày",
                    value:"ngay",
                    width:"120px"
                },
                {
                    text:"Ca",
                    value:"ca",
                    width:"70px"
                },
                {
                    text:"Nhận xét chung",
                    value:"comment",
                    width: "250px"
                },
                {
                    text:"Đi học",
                    value:"di_hoc",
                    width: "70px"
                },
                {
                    text:"Tích cực phát biểu",
                    value:"phat_bieu",
                    width: "90px"
                },
                {
                    text:"Tuân thủ nội quy",
                    value:"noi_quy",
                    width: "90px"
                },
                {
                    text:"Điểm ý thức",
                    value:"y_thuc",
                    width: "70px"
                },
                {
                    text:"Hoàn thành các hoạt động được giao",
                    value:"hoat_dong",
                    width: "150px"
                },
                {
                    text:"Làm bài tập ở nhà",
                    value:"bai_tap",
                    width: "150px"
                },
                {
                    text:"Điểm làm bài tập",
                    value:"diem_bt",
                    width: "90px"
                },
                {
                    text:"Số sao trên lớp",
                    value:"so_sao",
                    width: "90px"
                },
                {
                    text:"Xếp loại",
                    value:"xep_loai",
                    width: "90px"
                },
                {
                    text:"Điểm thưởng",
                    value:"diem_thuong",
                    width: "90px"
                },
                {
                    text:"Tổng điểm",
                    value:"tong_diem",
                    width: "90px"

                },
                {
                    text:"Kiến thức cần chú ý",
                    value:"kien_thuc",
                    width: "250px"
                },
                {
                    text:"Số sao trung bình/ ngày trong tháng",
                    value:"sao_tb",
                    width: "150px"
                },
                { text: 'Actions', value: 'actions', sortable: false ,width: "80px" }
            ],
            desserts: []
        }
    },
    created(){
        const postData = {
            command: "G_R_HS",
            parameter:{
                hoc_sinh:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.desserts = res.data           
        })
    },
    methods:{
        download(item){            
            
            if (item.filename == null) {
                alert("Ca học này không có ảnh")
                return
            }
            
            let url = "/Baihoc/DownloadImgBG?hp_comment_id=" + item.hp_comment_id
            api.getFile(url).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.filename);
                document.body.appendChild(fileLink);

                fileLink.click();
                document.body.removeChild(fileLink)
            })   
        }
    }
}
</script>