<template>
  <v-container fluid>
      <v-row justify="center" v-for="(response, index) in question.responses" :key="index">
        <v-col md="12" cols="12" align="center" v-if="index == 0">
            <p>
              <template                    
                    v-for="se in sentens"                    
                    
                >
                    <template v-if="se.type == 'text'">{{se.name}}</template>
                    <template v-if="se.type == 'drop'" >
                      <draggable v-if="se.type == 'drop'" :key="se.order" v-model="templist[se.order]"
                      class="list-group-drop"
                      tag="ul"                       
                      v-bind="dropOptions"
                      @end="endDrag()"
                    >
                      <li
                          class="list-group-item-drop"
                          v-for="element in templist[se.order]"
                          :key="element.order"
                      >
                          <i
                          :class="
                              element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                          "
                          @click="element.fixed = !element.fixed"
                          aria-hidden="true"
                          ></i>            
                          {{ element.name }}
                      </li>
                    </draggable>
                    </template>
                    
              </template>
            </p>            
        </v-col>
        
        <v-col md="12" cols="12" align="center" 
            v-if="index == 1" >
            <draggable
            class="list-group"
            tag="ul"
            v-model="list"
            v-bind="dragOptions"
            @start="startDrag()"
            @end="endDrag()"
            @move="move"
            @change="log"
        >
            <transition-group type="transition" name="flip-list">
            <li
                class="list-group-item"
                v-for="element in list"
                :key="element.order"
            >
                <i
                :class="
                    element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="element.fixed = !element.fixed"
                aria-hidden="true"
                ></i>            
                {{ element.name }}
            </li>
            </transition-group>
        </draggable>
        </v-col>
    </v-row>
  </v-container>
  
</template>
<script>
import draggable from 'vuedraggable'
import Vue from 'vue'

export default {
    name:"sortsentencequiz",
    components: {
            draggable,
    },
    props:[
        "question",
        "userResponses",
        "questionIndex",
        "words",
        "sentences"
    ],
    data() {
        return {
            list: this.words.map((name, index) => {
                return { name, order: index + 1 }
            }),
            isDragging: false,
            sentens:[],
            templist:{}
        };
    },
    created(){
      let parts = this.sentences.split("_")

      let order = 0

      for(let i=0;i<parts.length;i++){

          let part = parts[i]
          
          order = order + 1

          let textItem = {
              name : part,
              order : order,
              type : "text",
              class : ""
          }

          this.sentens.push(textItem)

          if (i == (parts.length - 1) && part == "") {
              continue
          }
          else {
                order = order + 1

                let spanItem = {
                    name : "",
                    order : order,
                    type : "drop",
                    class : "dragtext"
                }

                this.templist[order] = []
                this.sentens.push(spanItem)
          }         
      }

      if (this.question.hasOwnProperty("matchtemp")) {
            let matchtemp = this.question.matchtemp

            this.list = matchtemp.list
            this.templist = matchtemp.templist


      }

    },    
    methods: {
      sort() {
        this.list = this.list.sort((a, b) => a.order - b.order)
        
      },
      getList(index){
        this.templist[index] = []
        return this.templist[index]
      },

      startDrag(evt){        
        this.isDragging = true
        console.log(evt)
      },
      endDrag() {
        this.isDragging = false

        let matchtemp = {
          list : this.list,
          templist : this.templist,
          sentences : this.getSentence()
        }

        Vue.delete(this.question,"matchtemp")
        Vue.set(this.question,"matchtemp", matchtemp)
      },
      getSentence(){
        let sentences = "";

        for(let i=0;i<this.sentens.length;i++){
          let item = this.sentens[i]

          if (item.type == "text"){
            sentences = sentences + item.name
          }
          else {
            if (this.templist.hasOwnProperty(item.order)){
              let temp = this.templist[item.order]

              for(let j=0;j<temp.length;j++){
                sentences = sentences + temp[j].name
              }
            }
          }
        }

        return sentences
      },
      move(evt) {
          console.log(evt)
      },
      cloneDog({ id }) {
        return {
            id: id++,
            name: `${id}`
        };
        },
      log(evt) {
          console.log(evt)
      }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: {
                  name: "drag",
                  pull: "drop",
                  put: true
                },
                disabled: false,
                ghostClass: "ghost"                
            };
        },
        dropOptions() {
          return {
                animation: 0,
                group: {
                  name : "drop",
                  pull : ["drag"],
                  put : true
                },
                disabled: false,
                ghostClass: "ghost"                
            };
        }
    }
}
</script>
<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  
  list-style-type: none;
  margin: 0px;
  padding: 5px;
  background-color: #bae1f5;
  border: solid 1px #82b5ce;
  border-radius: 3px;
  min-height: 44px; 
}
.list-group-drop {
  padding-left: 0px !important;
  list-style-type: none;  
  border-bottom: solid 1px #82b5ce;
  max-height: 28px;
  min-width: 20px;
  display: inline-block;
}
.list-group-item-drop {
  cursor: move;  
  display: inline-block;
  height: auto;
  width: auto;
  border: 2px dotted #aaa;
  background-color: white;
  padding-left: 2px;
  padding-right: 2px;  
}
.list-group-item {
  cursor: move;  
  display: inline-block;
  height: 35px;
  width: auto;
  border: 2px dotted #aaa;
  background-color: white;
  padding: 5px;
  margin: 3px;
}
.list-group-item i {
  cursor: pointer;
}
.dragtext {
    border: 1px dotted #5cb1dc;
    background-color: #dff4ff;
    padding: 2px 4px;
    min-width: 40px;
    height: 1.1em;
    display: inline-flex;
    text-align: center;
    line-height: 30px;
    top: 0.31em;
    position: relative;
}
</style>