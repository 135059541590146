const state = {
    hocsinh: null,
    lop: null,
    khoahoc: null,
    mode: 0,
    assessItem: null
}

const actions = {
    setHocSinh ({ commit }, hocsinh ) {        
        commit("setHocSinh", hocsinh)
    },
    setLop ({ commit }, lop ) {        
        commit("setLop", lop)
    },
    setKhoaHoc ({ commit }, kh ) {        
        commit("setKhoaHoc", kh)
    },
    setMode ({ commit }, mode ) {        
        commit("setMode", mode)
    },
    setAssessItem({ commit }, item){
        commit("setAssessItem", item)
    }
}

const mutations = {
    setHocSinh (state, hocsinh) {
        state.hocsinh = hocsinh
    },
    setLop (state, lop) {
        state.lop = lop
    },
    setKhoaHoc (state, kh) {
        state.khoahoc = kh
    },
    setMode (state, mode) {
        state.mode = mode
    },
    setAssessItem (state, item) {
        state.assessItem = item
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations  
}