import api from "@/utils/backend-api";

const state = {
  callingAPI: false,
  searching: "",
  user: null,
  token: null,
  mode: "",
  menuName:"",
  userInfo: {
    messages: [],
    notifications: [],
    tasks: []
  },
  menus:[]  
}

const actions = {
  updateUser ({ commit }, { user, token }) {
    commit("setToken", token)
    commit("setUser", user)
  },
  updateMenuName ({ commit }, name ) {
    commit("setMenuName", name)    
  },
  updateMenus ({ commit }, menus ) {
    commit("setMenus", menus)    
  },
  logout ({ commit }) {
    commit("setToken", null)
    commit("setUser", {})
  },
  notify({commit}) {
    
    let postData = {
        command:"G_NO_VUE",
        parameter:{
            ma_dvcs:""
        }
    };

    api.postData("/System/ExecuteCommand",postData).then(res => {

      let info = res.data

      let notifications = []

      for(let i=0;i<info.length;i++) {
        let item = info[i]

        if (item.slg != null && item.slg != 0){
          notifications.push(item)
        }
      }

      commit("setUserInfo", {
        notifications: notifications
      })
      
    });
  }
}

const mutations = {
  loginLoading (state) {
    state.callingAPI = !state.callingAPI
  },
  globalSearching (state) {
    state.searching = state.searching === "" ? "loading" : ""
  },
  setUser (state, user) {
    state.user = user
  },
  setToken (state, token) {
    state.token = token
  },
  setUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },
  setMenuName (state, name) {
    state.menuName = name;
  },
  setMenus (state, menus) {
    state.menus = menus;
  }  
}

export default {
  namespaced: true,
  state,
  actions,
  mutations  
}