import axios from 'axios'
import https from 'https'
//const BASE_URL = 'https://schedule.tcm.edu.vn/api'
const BASE_URL = document.location.origin + '/api'

const instance = axios.create({
  httpsAgent: new https.Agent({  
    rejectUnauthorized: false
  }),
  baseURL: BASE_URL,
  timeout: false,
  params: {} // do not remove this, its added to add params later in the config
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  
  const {token} = window.Store.state.user
  
  if (token) {
    config.headers.common['Authorization'] = 'Bear ' + token
    config.headers.common['Access-Control-Allow-Origin'] = '*'
  } else {
    // Use application/x-www-form-urlencoded for login
    config.headers.common['Content-Type'] = 'application/json'
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => response, (error) => {
  
  return Promise.reject(error)
})

export default {
  getData (action) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url)
  },
  getFile (action){
    let url = `${BASE_URL}`
    url += action

    let options = {
      responseType: 'blob'
    }

    return instance.get(url,options)
  },
  postData (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data)
  },
  uploadFile (action, data, onUploadProgress) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    })
  },  
  putData (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.put(url, data)
  },
  deleteData (action) {
    let url = `${BASE_URL}`
    url += action
    return instance.delete(url)
  },
  login (action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data)
  }
}