<template>
    <div class="text-center">
        <div class="three-balls" v-if="isListening == true">
            <div class="ball ball1"></div>
            <div class="ball ball2"></div>
            <div class="ball ball3"></div>
        </div>
        <div style="width:100%">
            <v-textarea label="Bạn hãy nói to:" rows="3"
          row-height="25" disabled v-model="resultSpeak" />
        </div>
    </div>
</template>
<script>
export default {
    props:[
        "resultSpeak",
        "isListening"
    ]
}
</script>