<template>
    <v-layout fill-height class="elevation-5">
        <v-card height="100%" width="100%">
            <v-card-text>
                <v-flex xs12 md12>
            <v-row align="center"
                justify="space-around" >
                <v-col md="8" cols="12" sm="12">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" sm="6">
                                    <v-combobox label="Lớp"
                                        item-text="ten_lop"
                                        item-value="hp_lop_id"
                                        :items="classItems"
                                        v-model="selectedClass"
                                        @change="classChange()"
                                        required
                                        :rules="[v => !!v || 'Bạn chưa chọn lớp']"
                                    >

                                    </v-combobox>
                                </v-col>
                                <v-col md="6" cols="12" sm="6">
                                    <v-combobox label="Khóa học"
                                        item-text="ten_khoahoc"
                                        item-value="hp_khoahoc_id"
                                        :items="courses"
                                        v-model="selectedCourse"
                                        required
                                        :rules="[v => !!v || 'Bạn chưa chọn khóa học']"
                                    >

                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>                    
                </v-col>
                                
                <v-col md="4" cols="12" sm="12" >
                    <div class="text-right">
                        
                    <v-btn
                        class="mx-2"
                        color="primary"
                        fab
                        dark
                        small
                        @click="loadData()"
                        >
                        <v-icon dark>
                            mdi-refresh
                        </v-icon>
                        
                    </v-btn>
                    <v-btn
                        fab
                        dark
                        small
                        color="green"
                        class="mx-2"
                        @click="add()"
                        >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        
                    </v-btn>
                    </div>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" cols="12" md="12">
                    <v-data-table :headers="headers" :items="btlopItems"
                        :items-per-page="10"
                    >   <template v-slot:item.actions="{ item }">
                            <v-btn
                                
                                icon                                
                                color="teal"
                                
                                @click="edit(item)"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn                               
                                
                                icon                            
                                color="red"
                                
                                @click="remove(item)"
                                
                            >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                            </v-btn>
                            <v-btn                                
                                
                                icon
                                color="purple"
                                                              
                                
                                @click="giaobtHS(item)"
                            >
                                <v-icon>
                                    mdi-account-group
                                </v-icon>
                            </v-btn>
                            <v-btn                                
                                
                                icon
                                color="light-green"
                                                              
                                
                                @click="showDlgHS(item)"
                            >
                                <v-icon>
                                    mdi-cryengine
                                </v-icon>
                            </v-btn>
                        </template>        
                    </v-data-table>
                </v-col>
            </v-row>
        </v-flex>
            </v-card-text>
        </v-card>
        
        <v-dialog
            v-model="dlgSelectHS"            
            max-width="550"                  
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">Bài tập của lớp</v-card-title>

                <v-card-text class="pa-2" >
                   
                    <v-form>
                        <v-container>
                            <v-row >
                            <v-col md="6" cols="12">
                                <v-menu
                                    ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="ngay"
                                        label="Ngày giao"
                                        hint="Định dạng: DD/MM/YYYY"
                                        persistent-hint
                                        
                                        v-bind="attrs"
                                         dense
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        no-title
                                        @input="menu1 = false"
                                    ></v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col md="6" cols="12">
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="den_han"
                                        label="Ngày đến hạn"
                                        hint="Định dạng: DD/MM/YYYY"
                                        persistent-hint
                                        
                                        v-bind="attrs"
                                         dense
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date2"
                                        no-title
                                        @input="menu2 = false"
                                    ></v-date-picker>
                                    </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete 
                                    v-model="baitap"                           
                                    label="Bài tập"
                                    hide-no-data
                                    hide-details
                                    :loading="loadingBT"
                                    :items="itemsBT"
                                    :search-input.sync="searchBT"
                                    item-text="ten_baitap"
                                    item-value="ma_baitap"
                                    cache-items
                                    return-object
                            ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12">
                                <v-textarea  
                                v-model="ghi_chu"                         
                            label="Ghi chú"
                             dense
                            ></v-textarea>
                            </v-col>
                        </v-row>
                        </v-container>
                        
                        
                        
                        
                            
                    </v-form>
                    
                    
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>                

                <v-btn
                    color="green darken-1"
                    text
                    @click="save()"
                >
                    <v-icon dark left>
                            mdi-content-save
                        </v-icon>
                    Lưu
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="dlgSelectHS = false"
                >
                    <v-icon dark left>
                            mdi-cancel
                        </v-icon>
                    Hủy bỏ
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dlgHS" fullscreen scrollable persistent>
                <v-card>
                    <v-card-title class="pa-0">
                        <v-toolbar dark
                            color="primary"  >
                            <v-toolbar-title>Danh sách học sinh</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                dark
                                @click="dlgHS = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-card-title>
                    <v-card-text class="pa-2">
                        <v-data-table :headers="studentHeaders" :items="studentItems"
                            :items-per-page="10"
                        >
                            <template v-slot:item.actions="{ item }">
                                
                                <v-btn                                
                                    
                                    icon
                                    color="light-green"
                                                                
                                    
                                    @click="showDlgDapan(item)"
                                >
                                    <v-icon>
                                        mdi-cryengine
                                    </v-icon>
                                </v-btn>
                            </template>           
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>                    
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dlgHS = false"
                    >
                        <v-icon dark left>
                                mdi-cancel
                            </v-icon>
                        Đóng
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="dlgDapan"
                          
                >
                <v-card>
                    <v-card-title class="headline grey lighten-2">Chi tiết</v-card-title>
                    <v-card-text class="pa-5">
                        
                            <v-data-table
                                :headers="headersDapan"
                                :items="itemsDapan"
                                :items-per-page="10"
                                class="elevation-1 pa-12"
                                height="300px"
                                ></v-data-table>
                                                         
                        
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>                

                    <v-btn
                        color="red darken-1"
                        text
                        @click="dlgDapan = false"
                    >
                        Đóng
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <confirm-dialog
                :dlgConfirm="dlgConfirm"
                :dlgItem="dlgItem"
                dlgConfirmTitle="Thông báo"
                dlgConfirmText="Bạn có muốn xóa không"
                @onConfirm="onConfirm"
                @onCancel="onCancel"
            />
            <confirm-dialog
                :dlgConfirm="dlgConfirmHS"
                :dlgItem="dlgItem"
                dlgConfirmTitle="Thông báo"
                dlgConfirmText="Bạn có muốn giao bài tập cho học sinh"
                @onConfirm="onConfirmHS"
                @onCancel="onCancelHS"
            />     
    </v-layout>    
</template>
<script>
import api from "@/utils/backend-api"
import auth from "@/utils/auth"
import Store from '@/store'
import { mapState } from "vuex"
import ConfirmDialog from "@/components/ConfirmDialog"

export default {
    data() {
        return {
            dlgConfirm:false,
            dlgConfirmHS:false,
            dlgHS: false,
            date: null,
            date2: null,
            loadingBT: false,
            itemsBT:[],
            searchBT: null,
            ngay: null,
            den_han: null,
            baitap:null,
            ghi_chu:null,
            hp_baitaplop_id: null,
            menu1: false,
            menu2: false,
            dlgItem:null,
            valid: true,
            classItems:[],            
            courses:[],
            selectedClass: null,
            selectedCourse:null,
            headers:[
                {
                    text:"Ngày giao",
                    value:"ngay1",
                    width: "120px"
                },
                {
                    text:"Bài tập",
                    value:"ma_baitap",
                    width: "150px"
                },
                {
                    text:"Ngày đến hạn",
                    value:"den_han1",
                    width: "150px"
                },
                {
                    text:"Số học sinh",
                    value:"so_hs",
                    width: "150px"
                },
                {
                    text:"Ghi chú",
                    value:"ghi_chu"
                },
                {
                    text:"Actions",
                    value: "actions",
                    sortable : false,
                    width : "180px"
                }
            ],
            btlopItems:[],
            studentHeaders:[
                {
                    text : "Họ tên",
                    value : "ho_ten",
                    width: "120px"
                },
                {
                    text : "Tên tiếng anh",
                    value : "ten_en",
                    width: "120px"
                },
                {
                    text : "Điểm",
                    value: "diem",
                    width: "70px"
                },
                {
                    text : "Ngày hoàn thành",
                    value: "ngay_ht1",
                    width: "150px"
                },
                {
                    text : "Trạng thái",
                    value: "trang_thai",
                    width: "100px"
                },
                {
                    text:"Actions",
                    value: "actions",
                    sortable : false,
                    width : "180px"
                }
            ],
            studentItems:[],
            dlgSelectHS : false,
            selectedStudent : [],
            dlgDapan: false,
            headersDapan:[
                {
                    text:"STT",
                    value:"stt"
                },
                {
                    text:"Question",
                    value: "question"
                },
                {
                    text:"Chọn",
                    value:"chon"
                },
                {
                    text:"Đáp án đúng",
                    value: "dapan"
                }
            ],
            itemsDapan:[]
        }
    },
    components:{
        ConfirmDialog
    },
    computed:{
        ...mapState("assessment", {
            lop: "lop",
            khoahoc: "khoahoc"
        }),
    },
    watch: {
      date () {
        this.ngay = this.formatDate(this.date)
      },
      date2() {
        this.den_han = this.formatDate(this.date2)
      },
      searchBT(val) {
        this.queryBT(val)
      },
    },
    created(){        
        
        let postData = {
            command: "G_L_GV",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.classItems = res.data           
        })

        postData = {
            command: "G_KH",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.courses = res.data           
        })

        postData = {
                command: "G_ASSESSM",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id,
                    hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id
                }            
            }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.assessmentItems = res.data           
        })
    },
    methods:{
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        queryBT(val) {

            if (val == "") return 

            let postData = {
                refid: "MA_BT",
                filtervalue: val           
            }
            
            this.loadingBT = true

            api.postData("/System/GetDataByRefId",postData).then(res => {
                this.itemsBT = res.data           
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.loadingBT = false))
        },
        classChange(){
            this.selectedCourse = {
                hp_khoahoc_id : this.selectedClass.hp_khoahoc_id,
                ma_khoahoc : this.selectedClass.ma_khoahoc,
                ten_khoahoc : this.selectedClass.ten_khoahoc
            }

            Store.dispatch("assessment/setLop", this.selectedClass)
            Store.dispatch("assessment/setKhoaHoc", this.selectedCourse)
        },
        loadData() {
            this.$refs.form.validate()

            if (this.valid == false) return

            let postData = {
                command: "G_BAITAP",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id,
                    hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.btlopItems = res.data           
            })
        },
        showDlgDapan(item) {
            
            this.dlgDapan = true            
            this.itemsDapan = JSON.parse(item.chitiet)

        },
        add() {
            this.$refs.form.validate()

            if (this.valid == true) {
                
                this.hp_baitaplop_id = null
                this.ngay = null
                this.den_han = null
                this.baitap = null
                this.ghi_chu = null

                this.dlgSelectHS = true
            }            
        },
        nextToAdd() {
            if (this.selectedStudent.length == 0) {
                alert("Bạn chưa chọn học sinh")
                return
            }

            let hocsinh = this.selectedStudent[0]
            

            Store.dispatch("assessment/setHocSinh", hocsinh)
            Store.dispatch("assessment/setMode", 1)

            let now = new Date()

            let assessItem = {
                hp_assessment_id: null,
                ngay: now,
                ngay1: now.toLocaleDateString("vi-VN"),
                hp_dmhs_id: hocsinh.hp_dmhs_id,
                ho_ten: hocsinh.ho_ten,
                hp_lop_id: this.selectedClass.hp_lop_id,
                ma_lop: this.selectedClass.ma_lop,
                ten_lop: this.selectedClass.ten_lop,
                hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id,
                ma_khoahoc : this.selectedCourse.ma_khoahoc,
                ten_khoahoc : this.selectedCourse.ten_khoahoc,
                comment: "",
                passed : ""
            }

            Store.dispatch("assessment/setAssessItem",assessItem)

            this.$router.push({
                name: "AddAssessment"                
            });
        },
        edit(item) {

            this.hp_baitaplop_id = item.hp_baitaplop_id
            this.ngay = item.ngay1
            this.den_han = item.den_han1
            
            let baitap = {
                ma_baitap : item.ma_baitap,
                ten_baitap : item.ten_baitap
            }

            let data = []
            data.push(baitap)

            this.itemsBT = data
            this.baitap = baitap

            this.ghi_chu = item.ghi_chu

            this.dlgSelectHS = true

            
        },
        save() {
            let postData = {
                hp_baitaplop_id: this.hp_baitaplop_id,
                hp_lop_id: this.selectedClass.hp_lop_id,
                hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id,
                ghi_chu: this.ghi_chu,
                ngay: this.ngay,
                den_han: this.den_han,
                ma_baitap: this.baitap.ma_baitap
            }

            api.postData("/Teacher/CreateHomeWork",postData).then(res => {
                let result = res.data 
                
                if (result.hasOwnProperty("error")){
                    alert(result.error)
                }
                else {                   

                    if (this.hp_baitaplop_id == null) {
                        
                        let item = {
                            ngay: this.ngay == null || this.ngay == "" ? null : this.parseDate(this.ngay),
                            ngay1: this.ngay,
                            den_han: this.den_han == null || this.den_han == "" ? null : this.parseDate(this.den_han),
                            den_han1: this.den_han,
                            ghi_chu: this.ghi_chu,
                            ma_baitap: this.baitap.ma_baitap,
                            hp_baitaplop_id: this.hp_baitaplop_id,
                            hp_lop_id : this.selectedClass.hp_lop_id,
                            hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id
                        }

                        this.btlopItems.push(item)
                    }
                    else {
                        for(let i=0;i<this.btlopItems.length;i++){
                            
                            let item = this.btlopItems[i]

                            if (item.hp_baitaplop_id == result.id) {

                                item.ngay = this.ngay == null || this.ngay == "" ? null : this.parseDate(this.ngay)
                                item.ngay1 = this.ngay
                                item.ghi_chu = this.ghi_chu
                                item.ma_baitap = this.baitap.ma_baitap
                                item.den_han1 = this.den_han
                                item.den_han = this.den_han == null || this.den_han == "" ? null : this.parseDate(this.den_han)

                                break
                            }                       

                        }
                    }

                    this.showDlgStudent(false)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.loadingBT = false))
        },
        showDlgHS(item) {
            this.dlgHS = true            

            let postData = {
                command: "G_HS_LBT",
                parameter:{
                    hp_baitaplop_id : item.hp_baitaplop_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.studentItems = res.data           
            })
        },
        remove(item) {
            this.dlgConfirm = true
            this.dlgItem = item
        },
        giaobtHS(item) {
            this.dlgConfirmHS = true
            this.dlgItem = item
        },
        onCancel() {
            this.dlgConfirm = false
        },
        onCancelHS() {
            this.dlgConfirmHS = false
        },
        onConfirm(item) {
            
            let postData = {
                hp_baitaplop_id: item.hp_baitaplop_id           
            }        

            api.postData("/Teacher/DeleteHomeWork",postData).then(res => {
                
                if (res.data.hasOwnProperty("error")){
                    alert(res.data.error)
                }
                else {
                    let idx = -1

                    for(let i=0;i<this.btlopItems.length;i++){
                        if (this.btlopItems[i].hp_baitaplop_id == item.hp_baitaplop_id){
                            idx = i
                            break
                        }
                    }

                    if (idx != -1) {
                        this.btlopItems.splice(idx,1)
                    }
                }

                this.dlgConfirm = false
            })
        },
        onConfirmHS(item) {
            
            let postData = {
                hp_baitaplop_id: item.hp_baitaplop_id           
            }        

            api.postData("/Teacher/CreateStudentHomeWork",postData).then(res => {
                
                if (res.data.hasOwnProperty("error")){
                    alert(res.data.error)
                }
                else {                    

                    for(let i=0;i<this.btlopItems.length;i++){
                        
                        let item = this.btlopItems[i]

                        if (item.hp_baitaplop_id == item.hp_baitaplop_id){
                            item.so_hs = res.data.so_hs
                            break
                        }
                    }                    
                }

                this.dlgConfirmHS = false
            })
        }
    }
}
</script>