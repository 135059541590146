<template>
    <v-layout fill-height style="background-color:white">
        <v-container fluid>
            <v-row>
                <v-col md="6" sm="6" cols="6">
                    FullName/ (Tên): <b>{{assessItem.ho_ten}}</b>
                </v-col>
                <v-col md="6" sm="6" cols="6">
                    Course/ (Khóa học): <b>{{assessItem.ten_khoahoc}}</b>
                </v-col>               
            </v-row>
            <v-row>
                <v-col md="6" sm="6" cols="6">
                    Class/ (Lớp): <b>{{assessItem.ten_lop}}</b>
                </v-col>
                <v-col md="6" sm="6" cols="6">
                    Date/ (Ngày nhận xét): {{ assessItem.ngay1}}
                </v-col>               
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-simple-table
                        fixed-header
                        
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th width="50" class="text-center">
                                        No
                                    </th>
                                    <th class="text-center">
                                        Skills/Kỹ năng
                                    </th>
                                    <th width="150" class="text-center">
                                        Satisfactory <br />
                                        (Đáp ứng) 
                                    </th>
                                    <th width="150" class="text-center">
                                        Need improvement <br />
                                        (Cần cố gắng hơn) 
                                    </th>
                                    <th width="150" class="text-center">                                        
                                        No  opportunity  to assess <br />
                                        (Không đủ tiêu chí đánh giá) 
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in assements"
                                    :key="item.stt"
                                >
                                    <td :style="item.bold == 'C' ? 'font-weight:bold':''">{{ item.stt }}</td>
                                    <td>
                                        <b><i>{{ item.skills_en }}</i></b> <br />
                                        {{ item.skills_vn }}
                                    </td>
                                    <td ><v-text-field
                                        v-model="item.satis"
                                        
                                        single-line
                                        v-if="item.bold != 'C' || item.stt == '7'"
                                        /></td>
                                    <td>
                                        <v-text-field
                                        v-model="item.improve"
                                        
                                        single-line
                                        v-if="item.bold != 'C' || item.stt == '7'"
                                        />
                                    </td>
                                    <td>
                                        <v-text-field
                                        v-model="item.opport"
                                        
                                        single-line
                                        v-if="item.bold != 'C' || item.stt == '7'"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea label="Teacher Comment" v-model="assessItem.comment" rows="5"
          row-height="20" ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select     
                        :items="cokhong"
                        label="Có đủ điều kiện để học cấp độ mới?"
                        v-model="assessItem.passed"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="text-right">
                        <v-btn class="ma-2" color="green" dark @click="save()">
                            <v-icon dark left>mdi-content-save-all</v-icon>
                            Ghi
                        </v-btn>
                        <v-btn class="ma-2" color="red" dark @click="cancel()">
                            <v-icon dark left>mdi-cancel</v-icon>
                            Hủy bỏ
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-layout>
</template>
<script>
import { mapState } from "vuex"
import Store from '@/store'
import api from "@/utils/backend-api"

export default {
    data() {
        return {
            comment:"",
            ngay: new Date(),
            cokhong:["Có","Không"],
            passed:null,
            assements:[
                {
                    stt : "1",
                    skills_en: "Listening/ Kỹ năng nghe",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "1.1",
                    skills_en: "The learner can listen attentively to the teacher and peers.",
                    skills_vn:"Học viên chăm chú lắng nghe giáo viên và các bạn cùng lớp.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "1.2",
                    skills_en: "The learner understands the teacher’s English instructions.",
                    skills_vn:"Người học hiểu hướng dẫn bằng tiếng Anh của giáo viên.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "1.3",
                    skills_en: "The learner can follow simple stories with understanding.",
                    skills_vn:"Học viên có thể thiểu và theo dõi được những câu chuyện trên lớp.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "1.4",
                    skills_en: "The learner can identify simple information from the listening text.",
                    skills_vn:"Học viên có thể xác định thông tin đơn giản từ các bài nghe.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "2",
                    skills_en: "Speaking / Kỹ năng nói",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "2.1",
                    skills_en: "The learner can repeat single words and simple phrases after a model.",
                    skills_vn:"Học viên có thể lặp lại từ và cụm từ mẫu.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "2.2",
                    skills_en: "The learner can produce single words and simple phrases.",
                    skills_vn:"Học viên có thể nêu được từ và cụm từ đơn giản.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "2.3",
                    skills_en: "The learner can produce simple sentences.",
                    skills_vn:"Học viên có thể nêu được những câu đơn giản.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "2.4",
                    skills_en: "The learner can answer simple questions.",
                    skills_vn:"Học viên có thể trả lời được các câu hỏi đơn giản.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "2.5",
                    skills_en: "The learner can ask simple questions.",
                    skills_vn:"Học viên có thể đưa ra những câu hỏi đơn giản.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "3",
                    skills_en: "Reading/ Kỹ năng đọc",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "3.1",
                    skills_en: "The learner can read single words.",
                    skills_vn:"Học viên có thể đọc được những từ đơn.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "3.2",
                    skills_en: "The learner can read simple sentences.",
                    skills_vn:"Học viên có thể đọc được những câu đơn giản.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "3.3",
                    skills_en: "The learner can read aloud with comprehension.",
                    skills_vn:"Học viên có thể đọc to và hiểu bài đọc.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "3.4",
                    skills_en: "The learner can read by taking turns in a dialogue.",
                    skills_vn:"Học viên có thể đọc những bài hội thoại.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "4",
                    skills_en: "Writing/ Kỹ năng viết",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "4.1",
                    skills_en: "The learner can trace correctly.",
                    skills_vn:"Học viên có thể tô lại từ một cách chính xác.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "4.2",
                    skills_en: "The learner can copy correctly.",
                    skills_vn:"Học viên có thể viết lại chính xác.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "4.3",
                    skills_en: "The learner can write single words correctly.",
                    skills_vn:"Học viên có thể viết lại chính xác các từ đơn.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "4.4",
                    skills_en: "The learner can write sentences correctly.",
                    skills_vn:"Học viên có thể viết lại các câu chính xác.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "5",
                    skills_en: "Vocabulary/ Nhớ từ vựng",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "5.1",
                    skills_en: "The learner recognizes the meaning of words when he/she hears them.",
                    skills_vn:"Học viên có thể hiểu được từ khi nghe.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "5.2",
                    skills_en: "The learner recognizes the meaning of words when he/she sees them.",
                    skills_vn:"Học viên có thể nhận diện được nghĩa từ khi đọc.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "5.3",
                    skills_en: "The learner can name people, objects and animals.",
                    skills_vn:"Học viên có thể nêu được tên người, các đồ vật, con vật.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "5.4",
                    skills_en: "The learner can recall words learnt previously.",
                    skills_vn:"Học viên có thể nói lại được từ của các bài học trước.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "6",
                    skills_en: "Social development/ Kỹ năng tương tác",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                },
                {
                    stt : "6.1",
                    skills_en: "The learner can co-operate in a group.",
                    skills_vn:"Học viên có thể hợp tác trên lớp theo nhóm.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "6.2",
                    skills_en: "The learner eagerly helps others.",
                    skills_vn:"Học viên nhiệt tình giúp đỡ bạn.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "6.3",
                    skills_en: "The learner communicates with ease with his/her peers.",
                    skills_vn:"Người học dễ dàng giao tiếp với các học viên cùng lớp.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "6.4",
                    skills_en: "The learner works hard in class and takes responsibility for  his/her work.",
                    skills_vn:"Học viên học tập chăm chỉ và có tinh thần trách nhiệm với bài được giao",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "6.5",
                    skills_en: "The learner can assess his/her own work.",
                    skills_vn:"Học viên có thể tự đánh giá được các bài của mình.",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"K"
                },
                {
                    stt : "7",
                    skills_en: "CÁC KIẾN THỨC CẦN CHÚ Ý",
                    skills_vn:"",
                    satis:"",
                    improve:"",
                    opport:"",
                    bold:"C"
                }
            ]
        }
    },
    created() {
        let hp_assessment_id = this.$route.params.id

        if (hp_assessment_id != undefined && hp_assessment_id != null){
            let postData = {
                command: "G_D_ASSE",
                parameter:{
                    hp_assessment_id: hp_assessment_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.assements = res.data           
            })
        }
    },
    computed:{
        ...mapState("assessment", {
            hocsinh: "hocsinh",
            mode: "mode",
            lop: "lop",
            khoahoc: "khoahoc",
            assessItem: "assessItem"      
        }),
    },
    methods:{
        cancel(){
            Store.dispatch("assessment/setMode", 0);

            this.$router.push({
                name: "Assessment"                
            });
        },
        save(){            

            let postData = {
                hp_assessment_id : this.assessItem.hp_assessment_id,
                hp_lop_id : this.assessItem.hp_lop_id,
                hp_khoahoc_id : this.assessItem.hp_khoahoc_id,
                hp_dmhs_id : this.assessItem.hp_dmhs_id,
                ngay : this.assessItem.ngay1,
                comment : this.assessItem.comment,
                passed: this.assessItem.passed ,
                assements: this.assements
            }

            api.postData("/Teacher/SaveAssessment",postData).then(res => {
                
                if (res.data.hasOwnProperty("error")){
                    alert(res.data.console.error)
                }
                else {
                    alert("Đã lưu thành công")
                    this.cancel()
                }
            })
        }
    }
}
</script>