<template>
  <v-row justify="center">
    <v-col md="6" cols="12" align="center" v-for="(response, index) in question.responses" 
        @click="selectOptions(index)"  :key="index">
        <div class="textquiz" :class="{'textquiz_selected': userResponses[questionIndex] == index}"><span>{{response.text}}</span></div>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue'
export default {
    name:"textquiz",
    props:[
        "question",
        "userResponses",
        "questionIndex"
    ],
    methods: {
      selectOptions(index){
        Vue.set(this.userResponses, this.questionIndex, index)
      }
    }
}
</script>