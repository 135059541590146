<template>
    <v-row justify="center" align="center" >
             <v-col class="shrink" md="8" xs="12" cols="12">
                                
                 <v-card  class="mx-auto"
                      v-if="type == 1"  >
                     <v-card-title>
                         Question: {{questionIndex + 1}}
                     </v-card-title>
                     <v-card-subtitle>
                         <v-progress-linear :value="(questionIndex/quiz.questions.length)*100"></v-progress-linear>
                         {{(questionIndex/quiz.questions.length)*100}}% hoàn thành - Thời gian còn lại: <span style="color:red;font-weight:bold"> {{minutes}} : {{seconds}} </span>
                     </v-card-subtitle>
                     <v-card-text>
                         <v-container>
                             <v-row justify="center">
                                <v-col align="center" cols="12">
                                    <span>{{ quiz.questions[questionIndex].text }}</span>
                                    <v-btn :color="speakerColor" v-if="quiz.questions[questionIndex].sound_text != '' && quiz.questions[questionIndex].sound_text != null" icon @click="speak(quiz.questions[questionIndex].sound_text)"> 
                                        <v-icon>mdi-volume-high</v-icon> 
                                    </v-btn>
                                    <v-btn icon @click="showDlgListening()" v-if="quiz.questions[questionIndex].type_quest == 'RL'" :color=" isListening == true ? 'red': '' " ><v-icon>mdi-microphone</v-icon></v-btn>
                                </v-col>
                                
                                
                            </v-row>
                            <v-row justify="center" v-if="isSpeechQuiz == true">
                                <v-col align="center" cols="12">
                                    <SpeechQuiz :resultSpeak="resultSpeak" :isListening="isListening"  />
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'SL' || quiz.questions[questionIndex].type_quest == 'LL' || quiz.questions[questionIndex].type_quest == 'RL'">
                                <v-col align="center" cols="12">
                                    <TextQuiz :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses" ></TextQuiz>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'IL'">
                                <v-col align="center" cols="12">
                                    <ImageQuiz :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses" ></ImageQuiz>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'ML'">
                                <v-col align="center" cols="12">
                                    <MatchQuiz ref="matchQuiz" :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses" ></MatchQuiz>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'SS'">
                                <v-col align="center" cols="12">
                                    <SortSentenceQuiz  :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses"
                                        :words="quiz.questions[questionIndex].responses[0].ten.split('|')" ></SortSentenceQuiz>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'HT'">
                                <v-col align="center" cols="12">
                                    <CompleteSentenceQuiz  :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses"
                                        :sentences="quiz.questions[questionIndex].responses[0].ten"
                                        :words="quiz.questions[questionIndex].responses[1].ten.split('|')" ></CompleteSentenceQuiz>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="quiz.questions[questionIndex].type_quest == 'WT'">
                                <v-col align="center" cols="12">
                                    <WriteQuiz  :question="quiz.questions[questionIndex]" 
                                        :questionIndex="questionIndex" :userResponses="userResponses"
                                        :sentences="quiz.questions[questionIndex].write_content"
                                         ></WriteQuiz>
                                </v-col>
                            </v-row>
                         </v-container>
                         
                     </v-card-text>
                     <v-card-actions>
                         <v-layout align-content-space-around>
                             <v-btn @click="prev()" v-if="questionIndex>0" class="ma-2" >
                             <v-icon left>mdi-arrow-left</v-icon>Quay lại
                         </v-btn>
                         <v-spacer></v-spacer>
                         <v-btn @click="next()" :disabled="isListening == true" color="primary" class="ma-2">
                             Tiếp<v-icon right>mdi-arrow-right</v-icon>
                         </v-btn>
                        </v-layout> 
                         
                     </v-card-actions>
                 </v-card>
                 <v-card v-if="type == 2" class="mx-auto">
                     <v-card-title>
                         Kết quả
                     </v-card-title>
                     <v-card-text>
                         <p>Tổng điểm: <b>{{ score() }} / {{ quiz.questions.length }}</b>.</p>
                         
                         <v-btn @click="restart()" :loading="loading" class="ma-2"
                                :disabled="loading"><v-icon left>mdi-cached</v-icon>Làm lại</v-btn>
                        <v-btn @click="showDlg()" class="ma-2"
                                ><v-icon left>mdi-alarm-panel-outline</v-icon>Đáp án</v-btn>
                     </v-card-text>
                 </v-card>
            </v-col>
            <v-dialog
                v-model="dlg"
                          
                >
                <v-card>
                    <v-card-title class="headline grey lighten-2">Đáp án</v-card-title>
                    <v-card-text class="pa-5">
                        
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :items-per-page="10"
                                class="elevation-1 pa-12"
                                height="300px"
                                ></v-data-table>
                                                         
                        
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>                

                    <v-btn
                        color="red darken-1"
                        text
                        @click="dlg = false"
                    >
                        Đóng
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dlgListening" persistent max-width="300px">
                <v-card>
                    <v-card-title>Bạn hãy đọc to</v-card-title>
                    <v-card-text class="pa-5">
                        <v-row align="center" justify="center">
                            <div class="pulse-ring">
                                
                            </div>
                            <v-btn
                                class="mx-2"
                                fab                                
                                large
                                dark
                                color="red"
                                >
                                <v-icon dark>
                                    mdi-microphone
                                </v-icon>
                            </v-btn>
                        </v-row>                          
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>                

                        <v-btn
                            color="red darken-1"
                            text
                            @click="showDlgListening(false)"
                        >
                            Đóng
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-row>      
</template>
<script>
import Vue from 'vue'
import api from "@/utils/backend-api"
import TextQuiz from '@/components/TextQuiz.vue'
import ImageQuiz from '@/components/ImageQuiz.vue'
import MatchQuiz from '@/components/MatchQuiz.vue'
import SortSentenceQuiz from '@/components/SortSentenceQuiz.vue'
import CompleteSentenceQuiz from '@/components/CompleteSentenceQuiz.vue'
import WriteQuiz from '@/components/WriteQuiz.vue'
import SpeechQuiz from '@/components/SpeechQuiz.vue'

let quiz = {      
   questions: []
}

let userResponseSkelaton = Array(quiz.questions.length).fill(null)

export default {
    components: {
        TextQuiz,
        SpeechQuiz,
        ImageQuiz,
        MatchQuiz,
        SortSentenceQuiz,
        CompleteSentenceQuiz,
        WriteQuiz       
    },
    data(){
        return {
            isStartQuiz:false,
            quiz: quiz,
            questionIndex: 0,
            userResponses: userResponseSkelaton,
            isActive: false,
            isSelected: null,
            isSpeechQuiz: false,
            isListening: false,
            type:0,
            loading:false,
            isSpeaking:false,
            speakerColor:"black",
            resultSpeak:"",
            dlg : false,
            dlgListening: false,
            headers:[
                {
                    text:"STT",
                    value:"stt"
                },
                {
                    text:"Question",
                    value: "question"
                },
                {
                    text:"Chọn",
                    value:"chon"
                },
                {
                    text:"Đáp án đúng",
                    value: "dapan"
                }
            ],
            items:[],
            interval: undefined,            
            minutes:0,
            seconds:0,
            voice:null,
            speech: null,
            recognition: null
        }
    },
    created(){
        
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.handleResize)

        this.speech = window.speechSynthesis

        if(this.speech.onvoiceschanged !== undefined)
        {
            this.speech.onvoiceschanged = this.populateVoiceList()
        }

        this.type = 1
        this.loadData()

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
        
        let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
        let SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList

        this.recognition = new SpeechRecognition()
        this.recognition.lang = 'en-US'
        this.recognition.continuous = false
        this.recognition.interimResults = false
        this.recognition.maxAlternatives = 1

        let colors = [ 'aqua' , 'azure' , 'beige', 'bisque', 'black', 'blue', 'brown', 'chocolate', 'coral' ]
        
        let grammar = '#JSGF V1.0; grammar colors; public <color> = ' + colors.join(' | ') + ' ;'
        

        let speechRecognitionList = new SpeechGrammarList()
        speechRecognitionList.addFromString(grammar, 1)

        this.recognition.grammars = speechRecognitionList

        this.recognition.onstart = this.onSpeechStart
        this.recognition.onresult = this.onSpeechResult
        this.recognition.onspeechend = this.onSpeechEnd
        this.recognition.onerror = this.onSpeechError
    },
    filters: {
      charIndex: function(i) {
         return String.fromCharCode(97 + i)
      }
   },      
    methods:{
        startQuiz(start) {
            this.isStartQuiz = start
            //this.type = 1
            this.loadData()            
        },
        restart(){
            this.questionIndex=0
            this.loadData()
            //this.userResponses=Array(this.quiz.questions.length).fill(null)
            //this.type = 1
        },
        selectOption(index) {
            Vue.set(this.userResponses, this.questionIndex, index)
            //console.log(this.userResponses);
        },
        next() {
            if (this.questionIndex < this.quiz.questions.length-1){
                this.questionIndex++ 
                
                if (this.isSpeaking){
                    window.speechSynthesis.cancel()
                }

                this.isSpeechQuiz = false
                this.isListening = false
            }
            else {
                //this.type = 2
                window.clearInterval(this.interval)
                this.saveQuizResult()
            }
                
        },
        prev() {
            if (this.quiz.questions.length > 0) this.questionIndex--

            if (this.isSpeaking){
                    window.speechSynthesis.cancel()
            }
        },        
        score: function() {
            var score = 0;
            for (let i = 0; i < this.userResponses.length; i++) {

                let question = this.quiz.questions[i]

                if (question.type_quest == "ML"){
                    let match_result = question.match_result                    

                    if (question.hasOwnProperty("matchtemp")){
                        let parts = match_result.split(",")
                        let matchtemp = question.matchtemp

                        let result = true

                        for(let j=0;j<parts.length;j++){
                            
                            let part = parts[j]
                            let correct = false

                            for(let k=0;k<matchtemp.length;k++){
                                let item = matchtemp[k]

                                let key1 = (item.from + 1) + "-" + (item.to + 1)
                                let key2 = (item.to + 1) + "-" + (item.from + 1)

                                if (key1 == part || key2 == part){
                                    correct = true
                                    break
                                }
                            }

                            if (correct == true){
                                continue
                            }
                            else {
                                result = false
                                break
                            }
                        }
                        
                        if (result){
                            score = score + 1
                        }
                    }
                }
                else if (question.type_quest == "SS") {
                    let match_result = question.match_result
                    let text = ""

                    if (question.hasOwnProperty("matchtemp")){
                        let matchtemp = question.matchtemp                       

                        for(let i=0;i<matchtemp.length;i++){
                            text = text + matchtemp[i].name

                            if (i != (matchtemp.length -1))
                                text = text + " "
                        }
                    }

                    if (match_result == text) {
                        score = score + 1
                    }
                }
                else if (question.type_quest == "HT" || question.type_quest == "WT") {
                    let match_result = question.match_result
                    let text = ""

                    if (question.hasOwnProperty("matchtemp")){
                        let matchtemp = question.matchtemp                       

                        text = matchtemp.sentences
                    }

                    if (match_result == text) {
                        score = score + 1
                    }
                }
                else {
                    if (
                    typeof this.quiz.questions[i].responses[
                        this.userResponses[i]
                    ] !== "undefined" &&
                    this.quiz.questions[i].responses[this.userResponses[i]].correct
                    ) {
                        score = score + 1
                    }
                }
                
            }
            return score            
        },
        loadData(){

            let postData = {
                command: "G_R_BT",
                parameter:{
                    hp_baitapresult_id: this.$route.params.id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                let result = res.data
                
                if (result.length > 0){
                   let questions = JSON.parse(result[0].data)

                   this.quiz.questions = questions
                   this.userResponses=Array(this.quiz.questions.length).fill(null)

                   this.type = 1

                   this.minutes = 9
                   this.seconds = 59
                   this.interval = setInterval(this.countDown, 1000)
                }
            })
        },
        showDlg(){

            this.dlg = true
            this.items = this.getQuizResult()
            
        },
        saveQuizResult() {

            let items = this.getQuizResult()

            let diem = 0

            for(let i=0;i<items.length;i++) {

                let item = items[i]

                if (item.chon == "Đ") {
                    diem = diem + 1
                }
            }

            let postData = {
                hp_baitapresult_id : this.$route.params.id,
                diem: diem,
                chitiet: items
            }

            api.postData("/Hocsinh/SaveHomeWorkResult",postData).then(res => {
                let result = res.data
                
                if (result.hasOwnProperty("error")) {
                    alert(result.error)
                }
            })
            .finally(() => {
                this.$router.push({
                    name: "StudentHomeWork"                    
                })
            })


        },
        getQuizResult() {

            let items = []

            for (let i = 0; i < this.userResponses.length; i++) {

                let question = this.quiz.questions[i]

                if (question.type_quest == "ML"){
                    let match_result = question.match_result                    
                    let chon = ""                    

                    if (question.hasOwnProperty("matchtemp")){
                        let parts = match_result.split(",")
                        let matchtemp = question.matchtemp

                        let result = true
                        chon = "S"

                        for(let j=0;j<parts.length;j++){
                            
                            let part = parts[j]
                            let correct = false

                            for(let k=0;k<matchtemp.length;k++){
                                let item = matchtemp[k]

                                let key1 = (item.from + 1) + "-" + (item.to + 1)
                                let key2 = (item.to + 1) + "-" + (item.from + 1)

                                if (key1 == part || key2 == part){
                                    correct = true
                                    break
                                }
                            }

                            if (correct == true){
                                continue
                            }
                            else {
                                result = false
                                break
                            }
                        }                     
                        

                        if (result){
                            chon = "Đ"                            
                        }                       
                        
                    }

                    let item = {
                        stt : i+1,
                        question : this.quiz.questions[i].text,
                        chon : chon,
                        dapan : match_result,
                        hp_question_id: question.hp_questions_id
                    } 

                    items.push(item)
                }
                else if (question.type_quest == "SS"){
                    let match_result = question.match_result
                    let text = ""

                    if (question.hasOwnProperty("matchtemp")){
                        let matchtemp = question.matchtemp                        

                        for(let i=0;i<matchtemp.length;i++){
                            text = text + matchtemp[i].name

                            if (i != (matchtemp.length -1))
                                text = text + " "
                        }


                    }

                    let chon = ""

                    if (text != "") {
                        chon = match_result == text ? "Đ" : "S"
                    }                    

                    let item = {
                        stt : i+1,
                        question : this.quiz.questions[i].text,
                        chon : chon,
                        dapan : match_result,
                        hp_question_id: question.hp_questions_id
                    }
                    
                    items.push(item)
                }
                else if (question.type_quest == "HT" || question.type_quest == "WT"){
                    let match_result = question.match_result
                    let text = ""

                    if (question.hasOwnProperty("matchtemp")){
                        let matchtemp = question.matchtemp
                        text = matchtemp.sentences
                    }

                    let chon = ""

                    if (text != ""){
                        if (match_result == text) {
                            chon = "Đ"
                        }
                        else {
                            chon = "S"
                        }
                    }
                    

                    let item = {
                        stt : i+1,
                        question : this.quiz.questions[i].text,
                        chon : chon,
                        dapan : match_result,
                        hp_question_id: question.hp_questions_id
                    }
                    
                    items.push(item)
                }
                else {
                    if (typeof this.quiz.questions[i].responses[this.userResponses[i]] === "undefined"){

                    let item = {
                        stt : i+1,
                        question : this.quiz.questions[i].text,
                        chon : "",
                        dapan : "",
                        hp_question_id: question.hp_questions_id
                    } 

                    let responses = this.quiz.questions[i].responses;

                    for(let j=0;j<responses.length;j++){
                        if (responses[j].hasOwnProperty("correct")){
                            item.dapan = responses[j].text
                            break
                        }
                    }

                    items.push(item)
                }
                else {
                    if (this.quiz.questions[i].responses[this.userResponses[i]].correct){

                            let item = {
                                stt : i+1,
                                question : this.quiz.questions[i].text,
                                chon : "Đ",
                                dapan : this.quiz.questions[i].responses[this.userResponses[i]].text,
                                hp_question_id: question.hp_questions_id
                            }

                            items.push(item)
                        }
                        else {

                            let item = {
                                stt : i+1,
                                question : this.quiz.questions[i].text,
                                chon : "S",
                                dapan : "",
                                hp_question_id: question.hp_questions_id
                            }

                            let responses = this.quiz.questions[i].responses;

                            for(let j=0;j<responses.length;j++){
                                if (responses[j].hasOwnProperty("correct")){
                                    item.dapan = responses[j].text
                                    break
                                }
                            }

                            items.push(item)
                        }
                    }
                }       
                
            }

            return items
        },
        countDown() {
            this.seconds--

            if (this.seconds == 0) {
                
                if (this.minutes == 0){

                    window.clearInterval(this.interval)
                    this.type = 2

                    return
                }

                this.minutes--
                this.seconds = 59
            }
            
        },
        speak(text) {
            this.isSpeaking = true;
            this.speakerColor = "red";

            const msg = new SpeechSynthesisUtterance();

            msg.onend = this.onEndSpeak;
            
            if (this.voice == null) {
                const voices = this.speech.getVoices();
                this.voice = voices.filter((el) => el.name.indexOf("Natasha")>0)[0];
            }

            text = text.replace(/_/g, "");
            msg.text = text;
            // Set the attributes.
            // msg.volume = 100
            msg.rate = 1;
            msg.pitch = 1;
            msg.voice = this.voice;
            this.speech.speak(msg);            
        },
        onEndSpeak(){
            this.speakerColor = "black"
            this.isSpeaking = false
        },
        populateVoiceList(){
            let voices = speechSynthesis.getVoices()
            this.voice = voices.filter((el) => el.name.indexOf("Natasha")>0)[0]
        },
        showDlgListening(){            

            if (this.isSpeechQuiz == false){
                this.isSpeechQuiz = true
            }

            if (this.isListening == false){
                this.recognition.start()
            }
            else {
                this.recognition.stop()
            }
            
        },
        onSpeechEnd(){
            this.isListening = false
            console.log("Stop listening.")
        },
        onSpeechStart() {
            console.log("We are listening. Try speaking into the microphone.")
            this.isListening = true
            this.resultSpeak = ""
        },
        onSpeechResult(event) {
            let transcript = event.results[0][0].transcript

            this.isListening = false
            this.resultSpeak = transcript

            let confidence = event.results[0][0].confidence

            console.log(transcript)
            console.log(confidence)
        },
        onSpeechError(event) {
            
            this.isListening = false

            if(event.error == 'no-speech') {
                this.resultSpeak = "No speech was detected. Try again."
            }
        },
        handleScroll (event) {

            if (this.$refs.matchQuiz === undefined)
                return
                
            this.$refs.matchQuiz.handleScroll(event)
        },
        handleResize (event) {

            if (this.$refs.matchQuiz === undefined)
                return
                
            this.$refs.matchQuiz.handleResize(event)
        }

    }
}
</script>