<template>
    <v-layout fill-height class="elevation-5">
        <v-card height="100%" width="100%">            
            <v-card-text>
                <v-container fluid>
                    <v-row>
                <v-col cols="12" md="3">
                    <v-row>
                        <v-col>
                            <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="dateFormatted"
                                    label="Ngày học"
                                    hint="Định dạng DD/MM/YYYY"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="date = parseDate(dateFormatted)"                                    
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    no-title
                                    @input="menu1 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>                        
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-list dense  two-line>
                                <v-subheader>Ca học</v-subheader>
                                <v-list-item-group                                    
                                    color="primary"
                                    v-model="selectedItem"
                                >
                                    <v-list-item
                                    v-for="(item, i) in items"
                                    :key="i"
                                    @click="loadComment(item)"
                                    >
                                    <v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                        <v-list-item-subtitle v-text="item.ten_lop" class="text-wrap"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="9">
                    <v-row>
                        <v-col>
                            <v-form>
                                <v-textarea
                                name="input-7-1"
                                label="Nhận xét chung"
                                v-model="commentGeneral"
                            
                                ></v-textarea>
                                <v-btn                                
                                    color="success"
                                    class="mr-4"
                                    @click="saveComment()"                               
                                >
                                    <v-icon
                                        left
                                        dark
                                    >
                                        mdi-content-save
                                    </v-icon>
                                    Lưu
                                </v-btn>

                                <v-btn
                                    color="blue-grey"
                                    class="mr-4 white--text"
                                    @click="showDlgUpload()"                              
                                >
                                    <v-icon
                                        left
                                        
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                    Ảnh
                                </v-btn>
                                <v-btn
                                    color="blue-grey"
                                    class="mr-4 white--text"
                                    @click="download()"  
                                    text
                                    v-if="filename != null"                          
                                >
                                    <v-icon
                                        left
                                        
                                    >
                                        mdi-cloud-download
                                    </v-icon>
                                    {{filename}}
                                </v-btn>                               
                            </v-form>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-list
                            subheader
                            two-line
                            >
                            

                            <v-subheader ><span style="font-weight:bold;font-size:16px">Danh sách học sinh</span></v-subheader>

                            <v-list-item
                                v-for="file in files"
                                :key="file.title"
                            >
                                <v-list-item-avatar>
                                <v-icon
                                    :class="file.color"
                                    dark
                                    v-text="file.icon"
                                ></v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                <v-list-item-title v-text="file.title"></v-list-item-title>

                                <v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                <v-btn icon @click="showDlg(file)">
                                    <v-icon color="grey lighten-1">mdi-comment-edit</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dlg"
            max-width="650">
            <v-card>
                <v-card-title class="headline grey lighten-2">{{dlgTitle}}</v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"                        
                        lazy-validation
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    
                                    <v-select
                                    v-model="hsComment.phat_bieu"
                                    :items="cokhongb"
                                    
                                    label="Tích cực phát biểu"
                                    
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select     
                                        :items="cokhong"
                                        label="Tuân thủ nội quy"
                                        v-model="hsComment.noi_quy"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-select 
                                    :items="hoatdongs"    
                                    v-model="hsComment.hoat_dong"
                                    label="Hoàn thành các hoạt động được giao"
                                    
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select   
                                    v-model="hsComment.bai_tap"
                                    label="Làm bài tập ở nhà"
                                    :items="lambai"
                                    ></v-select>
                                </v-col>
                            </v-row>                            
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field     
                                    v-model="hsComment.so_sao"
                                    label="Số sao trên lớp"
                                    
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    
                                    <v-select
                                    v-model="hsComment.di_hoc"
                                    :items="cokhong"
                                    
                                    label="Đi học"
                                    
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field     
                                    v-model="hsComment.kien_thuc"
                                    label="Kiến thức cần chú ý"
                                    
                                    ></v-text-field>
                                </v-col>
                            </v-row>                            
                        </v-container>                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="saveHsComment()"
                    >
                        Lưu
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dlg = false"
                    >
                        Đóng
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dlgUpload"
            max-width="350">
            <v-card>
                <v-card-title class="headline grey lighten-2">{{dlgTitle}}</v-card-title>
                <v-card-text>
                    <v-form
                        ref="form"                        
                        lazy-validation
                    >
                        <v-container>
                            <v-file-input
                            show-size
                            label="Chọn File ảnh"
                            @change="selectFile"
                        ></v-file-input> 
                        </v-container>
                                              
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="upload()"
                    >
                        Lưu
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dlgUpload = false"
                    >
                        Đóng
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        
    </v-layout>  
</template>
<script>
import api from "@/utils/backend-api";
import auth from "@/utils/auth";

export default {
    data(){
        return {
            date: new Date().toISOString().substr(0, 10),
            dateFormatted:"",
            selectedItem: 1,
            menu1: false,        
            items: [],
            cahoc: null,
            commentGeneral:"",
            files: [],
            dlg: false,
            dlgTitle: "",
            dlgUpload:false,
            cokhongb:["Có","Không","Bình thường"],
            cokhong:["Có","Không"],
            lambai:["Không làm bài","Làm bài chưa đủ","Làm bài đầy đủ","Không có bài giao về"],
            hoatdongs:["Học thuộc câu chuyện","Học thuộc bài hát","Học thuộc và nói được chủ đề","Hoạt động khác","Không có hoạt động giao về"],
            hsComment:{
                phat_bieu:"",
                noi_quy:"",
                hoat_dong:"",
                bai_tap:"",
                so_sao:"",
                kien_thuc:"",
                di_hoc:"",
                ghi_chu:""
            },
            selectedHsComment: null,
            currentFile: undefined,
            progress: 0,
            filename:null,
            hp_comment_id:null
        }
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
        this.loadClassSchedule(val)
      }         
    },

    methods: {
      formatDate (date) {
        if (!date) return null
        
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month,  year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      loadClassSchedule(val){
        
        if (val == null) return

        const postData = {
            command: "CM00023",
            parameter:{
                user_login: auth.getUserName(),
                ma_dvcs: auth.getDvcs(),
                ngay:val
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            let data = res.data           
            
            this.items.splice(0,this.items.length)
            this.selectedItem = null

            this.cahoc = null
            this.filename = null
            this.hp_comment_id = null

            this.files.splice(0,this.files.length)
            this.commentGeneral = ""
            
            for(let i=0;i<data.length;i++){
                
                let item = {
                    text: data[i].ten_ca,
                    icon:"mdi-clock",
                    hp_lop_id: data[i].hp_lop_id,
                    hp_cahoc_id: data[i].hp_cahoc_id,
                    ten_lop: data[i].ten_lop,
                    ngay: val
                }
                
                this.items.push(item)
                
            }
            
        })
      },
      loadComment(selectedItem){
        if (this.selectedItem === selectedItem) return;

        this.cahoc = selectedItem;

        let postData = {
            command: "CM00022",
            parameter:{
                hp_lop_id: selectedItem.hp_lop_id,
                hp_ca_id: selectedItem.hp_cahoc_id,
                ngay:selectedItem.ngay
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            let data = res.data           
            
            this.commentGeneral=""            

            if (data.length > 0){
                this.commentGeneral = data[0].comment
                this.filename = data[0].filename
                this.hp_comment_id = data[0].hp_comment_id
            }            
            
        })

        postData = {
            command: "GET_DMHS",
            parameter:{
                hp_lop_id: selectedItem.hp_lop_id                
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            let data = res.data           
            
            this.files=[]           

            if (data.length > 0){                

                for(let i=0;i<data.length;i++){
                
                    let item = {
                        color: 'blue',
                        icon: 'mdi-account',
                        subtitle: "",
                        title: data[i].ho_ten,
                        ho_ten: data[i].ho_ten,
                        hp_dmhs_id: data[i].hp_dmhs_id
                    }
                    
                    this.files.push(item)

                }
            }            
            
        })
      },
      saveComment(){
          
          let postData = {
              hp_lop_id: this.cahoc.hp_lop_id,
              hp_ca_id: this.cahoc.hp_cahoc_id,
              ngay: this.cahoc.ngay,
              comment: this.commentGeneral
          }

          api.postData("/Teacher/SaveComment",postData).then(res => {
              let data = res.data

              if (data.hasOwnProperty("error")){
                  alert(data.error)
              }
              else {
                  alert("Lưu nhận xét thành công")
              }
          })
      },
      showDlg(hs){
          
          this.dlgTitle = "Nhận xét học sinh: " + hs.ho_ten
          this.dlg = true;          

          let postData = {
              hp_lop_id: this.cahoc.hp_lop_id,
              hp_ca_id: this.cahoc.hp_cahoc_id,
              hp_dmhs_id: hs.hp_dmhs_id,
              ngay: this.cahoc.ngay              
          }

          api.postData("/Teacher/GetHsComment",postData).then(res => {
              let data = res.data

              if (data != ""){
                  this.hsComment = data
              }
              else {
                  this.hsComment = {
                        phat_bieu:"",
                        noi_quy:"",
                        hoat_dong:"",
                        bai_tap:"",
                        so_sao:"",
                        kien_thuc:"",
                        di_hoc:"",
                        ghi_chu:"",
                        comment:"",                        
                        hp_lop_id: this.cahoc.hp_lop_id,
                        hp_ca_id: this.cahoc.hp_cahoc_id,
                        hp_dmhs_id: hs.hp_dmhs_id,
                        ngay: this.cahoc.ngay  
                    }
              }
          })
      },
      saveHsComment(){         

          api.postData("/Teacher/SaveHsComment",this.hsComment).then(res => {
              let data = res.data

              if (data.hasOwnProperty("error")){
                  alert(data.error)
              }
              else {
                  this.dlg = false;
                  alert("Lưu nhận xét thành công")
              }
          })
      },
      showDlgUpload(){
          this.dlgUpload = true;
          this.dlgTitle = "Tải ảnh lên"
      },
      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
      },
      upload() {
        if (!this.currentFile) {
            alert('Bạn chưa chọn file');
            return;
        }

        let formData = new FormData();

        formData.append("hp_lop_id",this.cahoc.hp_lop_id)
        formData.append("hp_ca_id", this.cahoc.hp_cahoc_id)
        formData.append("ngay", this.cahoc.ngay)
        formData.append("upload", this.currentFile);
        
        api.uploadFile("/Baihoc/UploadImgBaiGiang", formData, (event) => {
            this.progress = Math.round((100 * event.loaded) / event.total);
        })
            .then((response) => {
                let data = response.data;
                
                if (data.hasOwnProperty("error")){
                    alert(data.error)
                }
                else {
                    alert("Đã upload xong")
                    
                    this.dlgUpload = false
                    this.currentFile = undefined
                    this.progress = 0
                }
            })            
            .catch(() => {
                this.progress = 0;
                this.message = "Could not upload the file!";
                this.currentFile = undefined;
            });
        },
        download(){
            let url = "/Baihoc/DownloadImgBG?hp_comment_id=" + this.hp_comment_id
            api.getFile(url).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.filename);
                document.body.appendChild(fileLink);

                fileLink.click();
                document.body.removeChild(fileLink)
            })   
        }
    }
}
</script>