<template>
    <v-layout fill-height style="background-color:white">
        <v-container fluid>
            <v-row align="center"
                justify="space-around">
                <v-col md="8" cols="12" sm="12">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-combobox label="Lớp"
                                        item-text="ten_lop"
                                        item-value="hp_lop_id"
                                        :items="classItems"
                                        v-model="selectedClass"
                                        @change="classChange()"
                                        required
                                        :rules="[v => !!v || 'Bạn chưa chọn lớp']"
                                    >

                                    </v-combobox>
                                </v-col>                                
                            </v-row>
                        </v-container>
                    </v-form>                    
                </v-col>
                                
                <v-col md="4" cols="12" sm="12" >
                    <div class="text-left">
                        
                    <v-btn
                        class="mx-2"
                        color="primary"                        
                        dark                        
                        @click="loadData()"
                        >
                        <v-icon dark left>
                            mdi-refresh
                        </v-icon>
                        Tải dữ liệu
                    </v-btn>                    
                    </div>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" cols="12" md="12">
                    <v-data-table :headers="headers" :items="assessmentItems"
                        :items-per-page="10"
                    >   <template v-slot:item.actions="{ item }">
                            <v-btn
                            elevation="4"
                            fab
                            class="teal mr-2"
                            small
                            dark
                            @click="showDlg(item)"
                            >
                            <v-icon>
                                mdi-card-text
                            </v-icon>
                            </v-btn>
                            
                        </template>        
                    </v-data-table>
                </v-col>
            </v-row>
            <v-dialog v-model="dlg" fullscreen scrollable persistent >
                <v-card>
                    <v-card-title class="pa-0">
                        <v-toolbar dark
                        color="primary"  >
                        <v-toolbar-title>Kết quả  học tập</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="dlg = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    </v-card-title>
                    
                    <v-card-text class="pa-5">
                        <v-layout fill-height>
                            <v-flex xs12>
                                <v-row>
                                    <v-col md="6" sm="6" cols="6">
                                        FullName/ (Tên): <b>{{assItem.ho_ten}}</b>
                                    </v-col>
                                    <v-col md="6" sm="6" cols="6">
                                        Course/ (Khóa học): <b>{{assItem.ten_khoahoc}}</b>
                                    </v-col>               
                                </v-row>
                                <v-row>
                                    <v-col md="6" sm="6" cols="6">
                                        Class/ (Lớp): <b>{{assItem.ten_lop}}</b>
                                    </v-col>
                                    <v-col md="6" sm="6" cols="6">
                                        Date/ (Ngày nhận xét): {{ assItem.ngay1}}
                                    </v-col>               
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-simple-table                                            
                                            
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th width="50" class="text-center">
                                                            No
                                                        </th>
                                                        <th class="text-center">
                                                            Skills/Kỹ năng
                                                        </th>
                                                        <th width="150" class="text-center">
                                                            Satisfactory <br />
                                                            (Đáp ứng) 
                                                        </th>
                                                        <th width="150" class="text-center">
                                                            Need improvement <br />
                                                            (Cần cố gắng hơn) 
                                                        </th>
                                                        <th width="150" class="text-center">                                        
                                                            No  opportunity  to assess <br />
                                                            (Không đủ tiêu chí đánh giá) 
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in assements"
                                                        :key="item.stt"
                                                    >
                                                        <td :style="item.bold == 'C' ? 'font-weight:bold':''">{{ item.stt }}</td>
                                                        <td>
                                                            <b><i>{{ item.skills_en }}</i></b> <br />
                                                            {{ item.skills_vn }}
                                                        </td>
                                                        <td ><v-text-field
                                                            v-model="item.satis"
                                                            
                                                            single-line
                                                            v-if="item.bold != 'C' || item.stt == '7'"
                                                            /></td>
                                                        <td>
                                                            <v-text-field
                                                            v-model="item.improve"
                                                            
                                                            single-line
                                                            v-if="item.bold != 'C' || item.stt == '7'"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                            v-model="item.opport"
                                                            
                                                            single-line
                                                            v-if="item.bold != 'C' || item.stt == '7'"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p style="font-weight:bold;">Teacher Comment: </p>
                                        <p>{{assItem.comment}} </p>                                        
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p style="font-weight:bold;">Có đủ điều kiện để học cấp độ mới? </p>
                                        <p>{{assItem.passed}} </p> 
                                        
                                    </v-col>
                                </v-row>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-layout>
</template>
<script>
import api from "@/utils/backend-api"
import auth from "@/utils/auth"

export default {
    data() {
        return {
            valid: false,
            dlg:false,
            classItems:[],
            selectedClass: null,
            courses: [],
            selectedCourse: null,
            headers:[
                {
                    text:"Ngày",
                    value:"ngay1",
                    width: "120px",
                    sortable : false,
                },
                {
                    text:"Họ tên",
                    value:"ho_ten",
                    width: "150px",
                    sortable : false,
                },
                {
                    text:"Teacher Comments",
                    value:"comment",
                    sortable : false,
                },
                {
                    text:"Học cấp độ mới",
                    value:"passed",
                    width: "150px",
                    sortable : false,
                },
                {
                    text:"Actions",
                    value: "actions",
                    sortable : false,
                    width : "150px"
                }
            ],
            assessmentItems:[],
            assements:[],
            assItem : {
                ho_ten: "",
                ten_khoahoc: "",
                ten_lop: "",
                ngay1: "",
                comment: "",
                passed: ""
            }
        }
    },
    methods: {
        classChange() {

        },
        loadData() {
            this.$refs.form.validate()

            if (this.valid == false) return

            let postData = {
                command: "G_ASSESSM",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id,
                    hp_khoahoc_id: this.selectedClass.hp_khoahoc_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.assessmentItems = res.data           
            })
        },
        showDlg(item) {            

            let postData = {
                command: "G_D_ASSE",
                parameter:{
                    hp_assessment_id: item.hp_assessment_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.assements = res.data

                if (this.assements.length > 0) {
                    
                    this.assItem = {
                        ho_ten : item.ho_ten,
                        ten_khoahoc: item.ten_khoahoc,
                        ten_lop: item.ten_lop,
                        ngay1: item.ngay1,
                        comment: item.comment,
                        passed: item.passed
                    }

                    this.dlg = true  
                }
                else {
                    alert("Không tìm thấy dữ liệu")
                }
                         
            })

        }
    },
    created(){
                
        let postData = {
            command: "G_L_CUS",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.classItems = res.data           
        })

        postData = {
            command: "G_KH",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.courses = res.data           
        })        
    },
}
</script>