<template>
    <v-layout fill-height style="background-color:white">
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-combobox
                    v-model="selectedClass"
                    :items="classItems"
                    label="Chọn lớp"
                    item-text="ten_lop"
                    item-value="hp_lop_id"
                    @change="cbChange()"
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                
                                class="mr-2"
                                color="orange darken-2"
                                @click="showDlg(item)"
                            >
                                mdi-badge-account-horizontal
                            </v-icon>
                            <v-icon
                                
                                class="mr-2"
                                color="blue darken-2"
                                @click="download(item)"
                            >
                                mdi-cloud-download
                            </v-icon>
                            
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="dlg"
                   
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">{{dlgTitle}}</v-card-title>

                <v-card-text >
                   
                    <v-flex>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                        :headers="studentHeaders"
                        :items="studentItems"
                        :items-per-page="10"
                        class="elevation-1 ma-2"
                    >
                        
                    </v-data-table>
                            </v-col>
                        </v-row>
                            
                        
                        
                    </v-flex>
                       
                    
                    
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>                

                <v-btn
                    color="red darken-1"
                    text
                    @click="dlg = false"
                >
                    Đóng
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </v-layout>
    
</template>
<script>
import api from "@/utils/backend-api";
import auth from "@/utils/auth";

export default {
    data(){
        return {
            classItems:[],
            selectedClass:null,
            desserts:[],
            headers: [
                {
                    text:"Ngày",
                    value:"ngay"
                },
                {
                    text:"Ca",
                    value:"ten_ca"
                },
                {
                    text:"Nhận xét chung",
                    value:"comment"
                },                
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            dlg:false,
            dlgTitle:"",
            studentItems:[],
            studentHeaders: [
                {
                    text:"Học sinh",
                    value:"ho_ten",
                    width: "250px"
                },
                {
                    text:"Đi học",
                    value:"di_hoc",
                    width: "70px"
                },
                {
                    text:"Tích cực phát biểu",
                    value:"phat_bieu",
                    width: "90px"
                },
                {
                    text:"Tuân thủ nội quy",
                    value:"noi_quy",
                    width: "90px"
                },
                {
                    text:"Điểm ý thức",
                    value:"y_thuc",
                    width: "70px"
                },
                {
                    text:"Hoàn thành các hoạt động được giao",
                    value:"hoat_dong",
                    width: "150px"
                },
                {
                    text:"Làm bài tập ở nhà",
                    value:"bai_tap",
                    width: "150px"
                },
                {
                    text:"Điểm làm bài tập",
                    value:"diem_bt",
                    width: "90px"
                },
                {
                    text:"Số sao trên lớp",
                    value:"so_sao",
                    width: "90px"
                },
                {
                    text:"Xếp loại",
                    value:"xep_loai",
                    width: "90px"
                },
                {
                    text:"Điểm thưởng",
                    value:"diem_thuong",
                    width: "90px"
                },
                {
                    text:"Tổng điểm",
                    value:"tong_diem",
                    width: "90px"
                },
                {
                    text:"Kiến thức cần chú ý",
                    value:"kien_thuc",
                    width: "250px"
                },
                {
                    text:"Số sao trung bình/ ngày trong tháng",
                    value:"sao_tb",
                    width: "150px"
                }                
            ],
        }
    },
    created(){
        const postData = {
            command: "G_L_CUS",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.classItems = res.data           
        })
    },
    methods:{
        cbChange(){
            this.loadComment(this.selectedClass.hp_lop_id)
        },
        loadComment(id){
            const postData = {
                command: "G_CM_LOP",
                parameter:{
                    hp_lop_id:id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.desserts = res.data           
            })
        },
        download(item){            
            
            if (item.filename == null) {
                alert("Ca học này không có ảnh")
                return
            }
            
            let url = "/Baihoc/DownloadImgBG?hp_comment_id=" + item.hp_comment_id
            api.getFile(url).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.filename);
                document.body.appendChild(fileLink);

                fileLink.click();
                document.body.removeChild(fileLink)
            })   
        },
        showDlg(item) {

            this.dlg = true

            const postData = {
                command: "G_HS_CM1",
                parameter:{
                    hp_lop_id: item.hp_lop_id,
                    hp_cahoc_id: item.hp_ca_id,
                    ngay : item.ngay2
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.studentItems = res.data           
            })
        }
    }
}
</script>