<template>
    <v-layout fill-height>
        <v-card class="elevation-0" width="100%" height="100%">
            <v-card-title class="title">
                <span class="headline">{{contents[0].ten_lop}}</span>
                <v-spacer></v-spacer>
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                    <v-btn
                        
                        icon
                        v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>

                    <v-list>
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        @click="menuTitleClick(item.id)"
                    >
                        <v-list-item-title><v-icon >{{item.icon}}</v-icon>
                        {{ item.title }}</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-card-subtitle>Time: {{contents[0].tu_gio}} - {{contents[0].den_gio}}
                <a v-if="contents[0].ht_hoc == 'Online'" href="#" @click="openRoom()">Học Online</a>
            </v-card-subtitle>
            <v-card-text>
                <v-container fluid grid-list-sm>
                    <v-layout row wrap>
          <v-flex
            md4 sm12 xs12            
            v-for="card in contents"
            :key="card.hp_detailtiet_id"            
          >
            <v-card>
              
                <iframe width="100%" height="315" :src="card.link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <v-card-text >
                    <span v-if="card.ghi_chu != null && card.ghi_chu !=''">{{card.ghi_chu}} <br /></span>
                    {{card.content}} <br />
                    Time: {{card.content_time}}
                    
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>                
  
                <v-btn icon :href="card.link1" target="_blank" color="red">
                  <v-icon>mdi-bookmark</v-icon>
                </v-btn>
  
                <v-btn icon :href="card.link2" target="_blank" color="green" >
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>

                <v-btn icon :href="card.link3" target="_blank" color="blue" >
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </v-btn>

                <v-btn icon :href="card.link4" target="_blank" color="indigo" >
                  <v-icon>mdi-apple-keyboard-command</v-icon>
                </v-btn>

                <v-btn icon :href="card.link5" target="_blank" color="teal" >
                  <v-icon>mdi-arrow-decision</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
                    </v-layout>
                </v-container>
                
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialog"
            max-width="450"            
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">{{dialogTitle}}</v-card-title>

                <v-card-text>
                    <v-container v-if="titleId === 'mnCustomer'" fluid>                        
                        <p>Tên khách hàng: {{contents[0].ten_dt}} </p>
                        <p>Địa chỉ: <a :href="contents[0].google_map" target="_blank"> {{contents[0].dia_chi}} </a> </p>                        
                        
                    </v-container> 
                    <v-container  v-else-if="titleId === 'mnFlash'" fluid>
                        <iframe width="100%" height="100%" :src="flashUrl" frameborder="0" ></iframe>
                    </v-container>                
                    <v-container  v-else fluid>
                        {{contents[0].lessionobjective}}
                    </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>                

                <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false"
                >
                    Đóng
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dlgRoom" fullscreen scrollable persistent>
            <div style="background-color:grey;width:100%;height:100%">
                <vue-jitsi-meet
                    ref="jitsiRef"
                    domain="meet.jit.si"
                    :options="jitsiOptions"
                    v-if="renderJitsi"
                ></vue-jitsi-meet>
            </div>
                     
        </v-dialog>
    </v-layout>
</template>
<script>
import { mapState } from "vuex";
import Store from '@/store';
import { JitsiMeet } from '@mycure/vue-jitsi-meet';

export default {
    components: {
        VueJitsiMeet: JitsiMeet
    },
    data() {
        return {
            cards: [],
            items: [
                { id:"mnCustomer", title: 'Thông tin khách hàng', icon:"mdi-account-search" },
                { id:"mnObjective", title: 'Mục tiêu bài học', icon:"mdi-book-information-variant" },                
            ],
            dialog: false,
            dialogTitle:"",
            titleId:"",
            flashUrl:"",
            dialogFullScreen:false,
            dlgRoom: false,
            renderJitsi: false
        }
    },
    methods:{
        menuTitleClick:function(id){
            this.titleId = id;            

            if (id == "mnCustomer"){
                this.dialogTitle = "Thông tin khách hàng";                
            }
            
            if (id == "mnObjective"){
                this.dialogTitle = "Mục tiêu bài học";                
            }

            this.dialog = true;
        },
        openFlashWindow:function(url){
            var html="<html><head><meta name=\"viewport\" content=\"width=device-width; height=device-height;\"></head><body marginwidth='0' marginheight='0'><embed name=\"plugin\" src=\""+url+"\" type=\"application/x-shockwave-flash\" width=\"100%\" height=\"100%\"></body></html>"
            var popup=window.open('', '_blank', 'toolbar=0,location=0,menubar=0,fullscreen=yes');
            popup.document.write(html);
            popup.moveTo(0, 0);
            popup.resizeTo(screen.width, screen.height);
        },
        openRoom: function() {

            let link_online = this.contents[0].link_online

            if (link_online.startsWith("http")){
                window.open(link_online)
            }
            else {
                this.dlgRoom = true
                this.renderJitsi = true
            }           
            
        },
        onIFrameLoad () {
            this.$refs.jitsiRef.addEventListener('readyToClose', this.readyToClose);
        },
        readyToClose() {
            this.dlgRoom = false            

            this.$nextTick(() => {
                // Add the component back in
                this.renderJitsi = false;
            })
        }        
    },
    computed:{
        ...mapState("calendars", {
            contents: "contents"        
        }),
        ...mapState("user", {
            user: "user"        
        }),
        jitsiOptions () {
            return {
                roomName: this.contents[0].link_online,
                noSSL: false,
                userInfo: {                    
                    displayName: this.user
                },
                configOverwrite: {
                enableNoisyMicDetection: false
                },
                interfaceConfigOverwrite: {
                SHOW_JITSI_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                SHOW_CHROME_EXTENSION_BANNER: false
                },
                onload: this.onIFrameLoad
            };
        }
    },
    created () {
      Store.dispatch('calendars/getContents', this.$route.params.id)
    },
}
</script>