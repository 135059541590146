<template>
  <v-app>
    <template v-if="!loggedIn">
      <router-view></router-view>
    </template>
    <template v-if="loggedIn">
      <v-app-bar app color="indigo accent-4" dark clipped-left   elevation="6">
            
            
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>    
        <v-toolbar-title class="headline text-uppercase">
          <span>{{ menuName }}</span>          
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y origin="center center"  transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-badge
              color="pink"
              dot
              
              v-on="on"
              offset-x="15"
              offset-y="15"
              v-if="userInfo.notifications.length > 0"
            >
              <v-btn large                
                    icon
                    text
                    v-on="on"
                    >
                <v-icon large>mdi-bell-outline</v-icon>
              </v-btn>
            </v-badge>
            <v-btn large                
                    icon
                    text
                    v-on="on"
                    v-if="userInfo.notifications.length == 0"
                    >
                <v-icon large>mdi-bell-outline</v-icon>
              </v-btn>
          </template>
          <v-list v-if="userInfo.notifications.length > 0" >
            <v-list-item v-for="(index,item) in userInfo.notifications" :key="index"
              @click="clickNotify(item)"
              >
              <v-list-item-title>{{item.text}}</v-list-item-title>
              <v-list-item-action>
                <v-avatar size="32" class="red">{{item.slg}}</v-avatar>
              </v-list-item-action>
            </v-list-item>            
          </v-list>
        </v-menu>
        
        
        <v-menu offset-y origin="center center"  transition="scale-transition">
            <template v-slot:activator="{ on }">
                
                <v-btn text
                  large                
                  icon
                  v-on="on"
                >
                  <v-icon x-large>account_circle</v-icon>
                                  
                </v-btn>
            </template>
            <v-list >
                <v-list-item>
            <v-list-item-avatar>
              <v-icon x-large >account_circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{user}}</v-list-item-title>
              
            </v-list-item-content>
            
          </v-list-item>
          <v-divider></v-divider>
                <v-list-item @click="showChangePass(true)"                
                >
                  <v-list-item-icon>
                    <v-icon>mdi-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Đổi mật khẩu
                    <v-list-item-subtitle>Change Password</v-list-item-subtitle>
                  </v-list-item-title>

                </v-list-item>
                <v-list-item @click="logout()"                
                >
                <v-list-item-icon >
                  <v-icon   >mdi-logout</v-icon>
                </v-list-item-icon>
                  <v-list-item-title>
                    Thoát
                    <v-list-item-subtitle>Logout</v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item>
                
              </v-list>
        </v-menu>
        
        
          
      </v-app-bar>
      <v-navigation-drawer  
              :mini-variant.sync="mini" v-model="drawer" 
               clipped app
               
              > 
          
        <v-list 
            dense
            nav
            
          >
            <v-list-item>
            
            
            <v-list-item-content>
              <v-list-item-title class="text-center text-h6">
                <v-img src="/assets/img/logo.jpg" 
                    ></v-img>
              </v-list-item-title>
              
            </v-list-item-content>

            
          </v-list-item>
          <v-divider></v-divider>
            <v-list-item-group              
               color="indigo accent-2"
            >
              <v-list-item
                v-for="item in items"
                :key="item.title"
                link 
                @click="clickMenu(item)"
              >
                <v-list-item-icon  >
                  <v-icon    >{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content  >
                  <v-list-item-subtitle   v-text="item.title" ></v-list-item-subtitle>
                  
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            
          </v-list>
          
        </v-navigation-drawer>
      <v-main class="grey lighten-3">
        <v-container fluid fill-height>
          <router-view></router-view>
              
            <!-- </v-flex>
          </v-layout> -->
        </v-container>
        <v-dialog v-model="dlgChangePass" max-width="450" >
          <v-card>
            <v-card-title class="headline grey lighten-2">Đổi mật khẩu</v-card-title>
            <v-card-text>
              <v-form ref="form" >
                <v-text-field
                  v-model="oldpass"                  
                  label="Mật khẩu cũ"
                  :type="'password'"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newpass"                  
                  label="Mật khẩu mới"
                  :type="'password'"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="confirmpass"                  
                  label="Xác nhận mật khẩu"
                  :type="'password'"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>                
                <v-btn
                    color="primary darken-1"
                    text
                    @click="changePass()"
                >
                    Nhận
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="showChangePass(false)"
                >
                    Hủy bỏ
                </v-btn>
                </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
    </template>    
    
  </v-app>

</template>

<script>
import auth from "./utils/auth";
import route from "./utils/route";
import { mapState } from "vuex";

export default {
  name: 'App',  
  data(){
    return {
        mini: false,
        drawer: window.innerWidth > 960,
        loggedIn: auth.loggedIn(),
        //menuName: auth.getMenuName(),
        dlgChangePass: false,
        oldpass:"",
        newpass:"",
        confirmpass:"",
        items: [],
        right: null,
    };
  },
  created(){
    
    this.loadMenu()

    auth.onChange = loggedIn => {        
        this.loggedIn = loggedIn;
        this.loadMenu()        
    }
  },
  computed: {
    ...mapState("user", {
      user: "user",
      menuName: "menuName",
      userInfo: "userInfo",
      menus: "menus"      
    }),
    auth () {
      return auth
    }
  },
  methods: {
    loadMenu() {
      if (this.loggedIn == true){
            /*let postData = {
                command: "MENU_VUE",
                parameter:{
                    menu:""
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                let data = res.data
                this.items = data          
                
                route.clearRoutes(this.$router)
                route.addRoutes(this.$router,this.items)

                if (data.length > 0){
                  this.clickMenu(data[0])
                }
                else {
                  this.$router.push({
                    name: "Blank"
                  });
                }
            })
            */
           this.items = this.menus          
                
            route.clearRoutes(this.$router)
            route.addRoutes(this.$router,this.items)

            if (this.menus.length > 0){
              this.clickMenu(this.menus[0])
            }
            else {
              this.$router.push({
                name: "Blank"
              });
            }
        }
    },
    clickMenu(item) {
      this.menuName = item.title
      
      this.$router.push({
        name: item.link
      });
    },
    clickNotify(item) {
      this.$router.push({
        name: item.url
      })
    },
    logout(){
      auth.logout()

      this.$router.push({
        name: "Login"
      });
    },
    showChangePass(isVisible){      
      this.confirmpass = "";
      this.newpass = "";
      this.oldpass = "";
      this.dlgChangePass = isVisible;
    },    
    changePass(){
      auth.changePass(this.oldpass,this.newpass,this.confirmpass,(res)=>{
        if (res.hasOwnProperty("error")){
          alert(res.error);
        }
        else {
          this.showChangePass(false);
          alert("Your Password has been changed/Đổi mật khẩu thành công");
        }
      });
    }
  }
};
</script>
