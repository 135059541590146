<template>
  
  <v-layout column >    
      <v-layout align-content-center row>        
        <v-flex md5 hidden-sm-and-down>
          <v-img :aspect-ratio="1"  src="/assets/img/graphic1.svg"></v-img> 
        </v-flex>
        <v-flex md7>
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs10 sm8 md8 lg8>
                <v-form ref="form" @submit.prevent="login">                  
                  <v-text-field name="email" v-model="email" label="Username/Tên truy cập"></v-text-field>
                  <v-text-field name="password" v-model="pass" label="Password/Mật khẩu" type="password" ></v-text-field>
                  <v-layout pa-2 align-center justify-center row>
                      <v-flex md12>
                        <v-checkbox v-model="isRemember"  label="Remember/Ghi nhớ?" required></v-checkbox>
                      </v-flex>
                      
                  </v-layout> 
                                  
                  <v-btn type="submit" elevation="5" dark block color="indigo accent-4">
                    Login/Đăng nhập                    
                  </v-btn>                  
                  
                  <v-btn @click="showRecoverPass(true)" block class="mt-2" >
                    Forgot password/Quên mật khẩu?
                  </v-btn>                  

                  <v-snackbar v-if="error" :timeout="timeout" :top="true"  v-model="error">
                    {{ text }}
                    <v-btn class="pink--text" @click.native="error = false">Close/Đóng</v-btn>
                  </v-snackbar>
                </v-form>
              </v-flex>
              
            </v-layout>
            
          </v-container>           
        </v-flex>
      </v-layout>
      <v-dialog v-model="dlgRecoverPass" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Recover Password/Khôi phục mật khẩu</span>
          </v-card-title>
          <v-card-text>
            <!-- <v-container> -->
              <v-stepper v-model="stepRecover">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="stepRecover > 1"
                    step="1"
                  >
                    Email
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="stepRecover > 2"
                    step="2"
                  >
                  Verification Code/Mã xác nhận
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                    New Password/Mật khẩu mới
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form  >
                      <v-text-field
                        label="Registered Email/Địa chỉ Email đã đăng ký *"
                        required
                        v-model="emailRegister"
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          :loading="loading"
                          :disabled="loading"
                          @click="sendConfirmCode()"
                          class="mr-4"
                        >
                          Tiếp tục
                        </v-btn>

                        <v-btn @click="showRecoverPass(false)" text>
                          Hủy bỏ
                        </v-btn>
                    </v-form>                  
                    
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-form  >
                      <v-text-field
                        label="Verification Code/Nhập mã xác nhận *"
                        required
                        v-model="confirmCode"
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          @click="nextToPass()"
                          class="mr-4"
                        >
                          Tiếp tục
                        </v-btn>

                        <v-btn @click="showRecoverPass(false)" text>
                          Hủy bỏ
                        </v-btn>
                    </v-form>

                    
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-form  >
                      <v-text-field
                        v-model="newPass"
                        label="New Password/Nhập mật khẩu mới *"
                        type="password"
                        required
                        >
                        </v-text-field>
                      <v-text-field
                        v-model="confirmPass"
                        label="Confirm Password/Xác nhận mật khẩu *"
                        type="password"
                        required
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          class="mr-4"
                          :loading="loading"
                          :disabled="loading"
                          @click="resetPass()"
                        >
                          Thực hiện
                        </v-btn>

                        <v-btn @click="showRecoverPass(false)" text>
                          Hủy bỏ
                        </v-btn>
                    </v-form>

                    
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            <!-- </v-container> -->
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-layout>  
</template>
<script>
import auth from '../utils/auth'
import api from "@/utils/backend-api"
import Vue from 'vue'

export default {
  data () {
    return {
      email: '',
      emailRegister:'',
      pass: '',
      error: false,
      text: '',
      timeout: 4000,
      dlgRecoverPass: false,
      stepRecover: 1,
      isRemember: false,
      loading: false,
      confirmCode:"",
      confirmPass:"",
      newPass:""
    }
  },
  created(){

    if (Vue.$cookies.isKey("appVueLogin")){

      let appVueLogin = Vue.$cookies.get("appVueLogin")

      this.email = appVueLogin.email      

      this.isRemember = true

    }
  },
  methods: {
    login () {
      auth.login(this.email, this.pass, (loggedIn, err) => {
        if (err) {          
          this.error = true;
          this.text = err;
        } else if (loggedIn === false) {          
          this.error = true;
          this.text = err;
        } else {          
            if (this.isRemember){

              let appVueLogin = {
                email : this.email
                
              }

              Vue.$cookies.set('appVueLogin',appVueLogin);

            }
            else {

              if (Vue.$cookies.isKey("appVueLogin")){

                Vue.$cookies.remove("appVueLogin")               

              }
            }
            
            auth.notify()
        } 
      })
    },
    showRecoverPass(isVisible){
      this.dlgRecoverPass = isVisible

      if (isVisible == false){
        this.stepRecover = 1
        this.emailRegister = ""
        this.confirmCode = ""
        this.loading = false
        this.confirmPass = ""
        this.confirmCode = ""
      }
    },
    sendConfirmCode(){

      if (this.emailRegister == "") {
        alert("Email is required/Bạn chưa nhập địa chỉ email")
        return
      }

      let postData = {
        email : this.emailRegister
      }

      this.loading = true

      api.postData("/Account/SendConfirmCode",postData).then(res => {
            let data = res.data 
            
            this.loading = false

            if (data.hasOwnProperty("error")){
              alert(data.error)
            }
            else {
              this.stepRecover = 2;
            }
      }).catch( e => {
        this.loading = false
        alert(e.message)
      })
    },
    nextToPass(){
      if (this.confirmCode == "") {
        alert("Verification Code is required/Bạn chưa nhập mã xác nhận")
        return
      }

      this.stepRecover = 3
    },
    resetPass(){

      if (this.confirmPass == "" || this.newPass == ""){
        alert("New Password or Confirm Password is required/Bạn chưa nhập mật khẩu mới hoặc xác nhận mật khẩu")
        return
      }

      if (this.confirmPass != this.newPass){
        alert("Confirm Password and New Password are not the same/Mật khẩu mới và xác nhận mật khẩu không giống nhau")
        return
      }

      let postData = {
        confirmCode : this.confirmCode,
        newPass: this.newPass,
        confirmPass: this.confirmPass,
        email: this.emailRegister
      }

      this.loading = true

      api.postData("/Account/RecoverPassByConfirmCode",postData).then(res => {
            let data = res.data 
            
            this.loading = false

            if (data.hasOwnProperty("error")){
              alert(data.error)
            }
            else {
              this.showRecoverPass(false)
              alert("Your Password has been changed/Đổi mật khẩu thành công")
              
            }
      }).catch( e => {
        this.loading = false
        alert(e.message)
      })

    }
  }
}
</script>