import Store from '@/store'
import api from "@/utils/backend-api"

export default {
    login (email, pass, cb) {
        cb = arguments[arguments.length - 1]
        //let data = 'username=' + email + '&pass=' + pass + '&dvcs=VP'
        
        let data = {
            username: email,
            password: pass,
            ma_dvcs: "VP",
            vue: "C"
        }

        api.login('/Account/Login', data).then((res) => {

            if (res.data.error!=undefined && res.data.error!=null){
                if (cb) cb(false,res.data.error)
                this.onChange(false)
                return
            }

            const data=JSON.parse(res.config.data)           
            const token = res.data.token + ";" + data.ma_dvcs+";vi"
            const user = data.username
            const menus = JSON.parse(res.data.menus)

            Store.dispatch('user/updateMenus', menus)
            Store.dispatch('user/updateUser', {user, token})

            if (cb) cb(true, null)
            this.onChange(true)
        }, (err) => {
        
        if (cb) cb(false, err)
        this.onChange(false)
        })
    },
    changePass(oldpass,newpass,confirmpass,cb){
        
        let postData={
            oldpass:oldpass,
            newpass:newpass,
            confirmpass:confirmpass
        };

        api.postData("/Account/ChangePass",postData).then((res)=>{
            cb(res.data);
        });
    },
    getToken () {
        return Store.state.user.token
    },
    getUserName () {
        return Store.state.user.user
    },
    getMenuName () {
        return Store.state.user.menuName
    },
    getMenu(){
        return Store.state.user.menu
    },
    getDvcs(){
        return "VP"
    },
    logout (cb) {
        // delete localStorage.token
        // Store.commit('setToken', null)
        Store.dispatch('user/logout')
        if (cb) cb(false)
        this.onChange(false)
    },    
    loggedIn () {
        return !!Store.state.user.token;
    },
    notify() {
        Store.dispatch('user/notify')
    },
    onChange () {}
}