<template>
  <v-row >
    <v-col md="6" cols="6"  v-for="(response, index) in question.responses" 
        @click="selectOptions($event,index)"   :key="index">
        <p> {{index + 1}} </p>
        <div  :ref="'ref_res_' + index" class="imagequiz" :class="{'imagequiz_selected': response.hasOwnProperty('selected')}"> 
                    
            <v-img  :src="response.url" v-if="response.url != null && response.url != ''" max-width="150" max-height="150"></v-img>
            <span  style="display:table-cell;vertical-align:middle;" v-else>{{response.ten}}</span>
           
        </div>
        
    </v-col>
    <div class="line" :ref="'ref_line_' + index" :style="line.style" v-for="(line,index) in lines" :key="index"
        @click="dropLine(index)">

    </div>
  </v-row> 
</template>
<script>
import Vue from 'vue'

export default {
    name:"imagequiz",
    props:[
        "question",
        "userResponses",
        "questionIndex"
    ],
    data() {
        return {
            tempObj:{
                from: null,
                to: null
            },
            lines:[]
        }
    },
    mounted(){
      for(let i=0;i<this.question.responses.length;i++){
        let item = this.question.responses[i]
        
        Vue.delete(item,"selected")
        Vue.delete(item,"match")

      }

      if (this.question.hasOwnProperty("matchtemp")){
        
        let matchTemp = this.question.matchtemp;
        let responses = this.question.responses

        for(let j=0;j<matchTemp.length;j++){
          let item = matchTemp[j]
          
          Vue.set(responses[item.from],"selected",true)
          Vue.set(responses[item.to],"selected",true)

          let key = item.from + "-" + item.to

          Vue.set(responses[item.from],"match",key)
          Vue.set(responses[item.to],"match",key)

          this.drawLine(item.from,item.to,true)
        }
      }
    },   
    methods: {
      selectOptions(ev,index){
        
        let response = this.question.responses[index]

        if (response.hasOwnProperty("match"))
          return

        if (index % 2 == 0) {

          for(let i=0;i<this.question.responses.length;i++){
            
            if (i % 2 == 0){
              let item = this.question.responses[i]

              if (item.hasOwnProperty("selected") && !item.hasOwnProperty("match")){
                  Vue.delete(item,"selected")
                  break
              }
            }           

          }

          Vue.set(response, "selected" , true)
          this.tempObj.from = index
        }

        if (index % 2 == 1) {

          for(let i=0;i<this.question.responses.length;i++){
            
            if (i % 2 == 1){
              let item = this.question.responses[i]

              if (item.hasOwnProperty("selected") && !item.hasOwnProperty("match")){
                  Vue.delete(item,"selected")
                  break
              }
            }           

          }

          Vue.set(response, "selected" , true)
          this.tempObj.to = index
        }

        if (this.tempObj.to != null && this.tempObj.from != null){
            this.drawLine(this.tempObj.from,this.tempObj.to)

            this.tempObj.from = null
            this.tempObj.to = null
        }

        let target = ev.currentTarget.getBoundingClientRect()
        console.log(target)        
      },
      drawLine(from,to,repaint){

        let [fromEl] = this.$refs['ref_res_' + from]
        let [toEl] = this.$refs['ref_res_' + to]

        let recTo = toEl.getBoundingClientRect()
        let recFrom = fromEl.getBoundingClientRect()

        let fT = recFrom.top  + recFrom.height/2;
        let tT = recTo.top    + recTo.height/2;
        
        let fL = recFrom.left + recFrom.width;
        var tL = recTo.left   ;
        
        let CA   = Math.abs(tT - fT);
        let CO   = Math.abs(tL - fL);
        let H    = Math.sqrt(CA*CA + CO*CO);
        let ANG  = 180 / Math.PI * Math.acos( CA/H );

        let top = null
        let left = null

        if(tT > fT){
          top  = (tT-fT)/2 + fT;
        }else{
          top  = (fT-tT)/2 + tT;
        }
        if(tL > fL){
          left = (tL-fL)/2 + fL;
        }else{
          left = (fL-tL)/2 + tL;
        }

        if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
          ANG *= -1;
        }

        top-= H/2;

               
        let  line = {
            from : from,
            to: to,
            style : "",
            top : top,
            left : left,
            H : H
          }

          line.style = line.style + "-webkit-transform:" + 'rotate('+ ANG +'deg);';
          line.style = line.style + "-moz-transform:" + 'rotate('+ ANG +'deg);';
          line.style = line.style + "-ms-transform:" + 'rotate('+ ANG +'deg);';
          line.style = line.style + "-o-transform:" + 'rotate('+ ANG +'deg);';
          line.style = line.style + "-transform:" + 'rotate('+ ANG +'deg);';
          line.style = line.style + "top:" + top+'px;';
          line.style = line.style + "left:" + left+'px;';
          line.style = line.style + "height:" + H+'px;';      
        
        
          this.lines.push(line)

          let key = from + "-" + to

          Vue.set(this.question.responses[from],"match",key)
          Vue.set(this.question.responses[to],"match",key)

          if (repaint === undefined) {
            if (!this.question.hasOwnProperty("matchtemp")){
              Vue.set(this.question,"matchtemp",[])
            }

            let matchTemp = this.question.matchtemp
            
            matchTemp.push({
              from: from,
              to: to
            })
          }    
                
      },
      dropLine(index){
        let line = this.lines[index];

        let from = this.question.responses[line.from]
        
        Vue.delete(from,"selected")
        Vue.delete(from,"match")

        let to = this.question.responses[line.to]
        
        Vue.delete(to,"selected")
        Vue.delete(to,"match")

        this.lines.splice(index,1)

        if (this.question.hasOwnProperty("matchtemp")){
          let matchtemp = this.question.matchtemp

          let idx = null

          for(let i=0;i<matchtemp.length;i++){
            let item = matchtemp[i]

            if (item.to == line.to && item.from == line.from) {
              idx = i
              break
            }
          }

          if (idx != null) {
            matchtemp.splice(idx,1)
          }
        }
      },
      handleScroll(){       

        for(let i=0;i<this.lines.length;i++){
            
            this.adjustLine(i)
        }
      },
      handleResize(){
        for(let i=0;i<this.lines.length;i++){
            
            this.adjustLine(i)
        }
      },
      adjustLine(idxLine){
        let line = this.lines[idxLine]
        let [lineEl] = this.$refs['ref_line_' + idxLine]

        let [fromEl] = this.$refs['ref_res_' + line.from]
        let [toEl] = this.$refs['ref_res_' + line.to]

        let recTo = toEl.getBoundingClientRect()
        let recFrom = fromEl.getBoundingClientRect()

        let fT = recFrom.top  + recFrom.height/2;
        let tT = recTo.top    + recTo.height/2;
        
        let fL = recFrom.left + recFrom.width;
        var tL = recTo.left   ;
        
        let CA   = Math.abs(tT - fT);
        let CO   = Math.abs(tL - fL);
        let H    = Math.sqrt(CA*CA + CO*CO);
        let ANG  = 180 / Math.PI * Math.acos( CA/H );

        let top = null
        let left = null

        if(tT > fT){
          top  = (tT-fT)/2 + fT;
        }else{
          top  = (fT-tT)/2 + tT;
        }
        if(tL > fL){
          left = (tL-fL)/2 + fL;
        }else{
          left = (fL-tL)/2 + tL;
        }

        if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
          ANG *= -1;
        }

        top-= H/2;

        lineEl.style.top = top + "px"
        lineEl.style.left = left + "px"
        lineEl.style.height = H + "px"

        lineEl.style.webkitTransform = 'rotate('+ ANG +'deg)'
        lineEl.style.transform = 'rotate('+ ANG +'deg)'

        console.log(ANG)
      }
    }
}
</script>