<template>
    <v-layout fill-height class="elevation-5">
        <v-card height="100%" width="100%">
            <v-card-text>
                <v-flex xs12 md12>
            <v-row align="center"
                justify="space-around" >
                <v-col md="8" cols="12" sm="12">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" sm="6">
                                    <v-combobox label="Lớp"
                                        item-text="ten_lop"
                                        item-value="hp_lop_id"
                                        :items="classItems"
                                        v-model="selectedClass"
                                        @change="classChange()"
                                        required
                                        :rules="[v => !!v || 'Bạn chưa chọn lớp']"
                                    >

                                    </v-combobox>
                                </v-col>
                                <v-col md="6" cols="12" sm="6">
                                    <v-combobox label="Khóa học"
                                        item-text="ten_khoahoc"
                                        item-value="hp_khoahoc_id"
                                        :items="courses"
                                        v-model="selectedCourse"
                                        required
                                        :rules="[v => !!v || 'Bạn chưa chọn khóa học']"
                                    >

                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>                    
                </v-col>
                                
                <v-col md="4" cols="12" sm="12" >
                    <div class="text-right">
                        
                    <v-btn
                        class="mx-2"
                        color="primary"
                        fab
                        dark
                        small
                        @click="loadData()"
                        >
                        <v-icon dark>
                            mdi-refresh
                        </v-icon>
                        
                    </v-btn>
                    <v-btn
                        fab
                        dark
                        small
                        color="green"
                        class="mx-2"
                        @click="add()"
                        >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        
                    </v-btn>
                    </div>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" cols="12" md="12">
                    <v-data-table :headers="headers" :items="assessmentItems"
                        :items-per-page="10"
                    >   <template v-slot:item.actions="{ item }">
                            <v-btn
                            elevation="4"
                            fab
                            class="teal mr-2"
                            small
                            dark
                            @click="edit(item)"
                            >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                            </v-btn>
                            <v-btn
                            elevation="4"
                            fab
                            color="red"
                            small
                            @click="remove(item)"
                            dark
                            >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                            </v-btn>
                        </template>        
                    </v-data-table>
                </v-col>
            </v-row>
        </v-flex>
            </v-card-text>
        </v-card>
        
        <v-dialog
            v-model="dlgSelectHS" 
            persistent
                              
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">Chọn học sinh</v-card-title>

                <v-card-text class="d-flex pa-2" >
                   
                    <v-flex xs12>
                        <v-data-table 
                        :headers="studentHeaders"
                        :items="studentItems"
                        :items-per-page="5"
                        class="elevation-1 ma-2"
                        fixed-header
                        single-select
                        item-key="hp_dmhs_id"
                        show-select
                        v-model="selectedStudent"
                    >
                        
                    
                
                    </v-data-table>
                            
                        
                        
                    </v-flex>
                       
                    
                    
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>                

                <v-btn
                    color="primary darken-1"
                    text
                    @click="nextToAdd()"
                >
                    Tiếp
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="showDlgStudent(false)"
                >
                    Đóng
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog> 
            <confirm-dialog
                :dlgConfirm="dlgConfirm"
                :dlgItem="dlgItem"
                dlgConfirmTitle="Thông báo"
                dlgConfirmText="Bạn có muốn xóa không"
                @onConfirm="onConfirm"
                @onCancel="onCancel"
            />       
    </v-layout>    
</template>
<script>
import api from "@/utils/backend-api"
import auth from "@/utils/auth"
import Store from '@/store'
import { mapState } from "vuex"
import ConfirmDialog from "@/components/ConfirmDialog"

export default {
    data() {
        return {
            dlgConfirm:false,
            dlgItem:null,
            valid: true,
            classItems:[],            
            courses:[],
            selectedClass: null,
            selectedCourse:null,
            headers:[
                {
                    text:"Ngày",
                    value:"ngay1",
                    width: "120px"
                },
                {
                    text:"Họ tên",
                    value:"ho_ten",
                    width: "150px"
                },
                {
                    text:"Teacher Comments",
                    value:"comment"
                },
                {
                    text:"Actions",
                    value: "actions",
                    sortable : false,
                    width : "150px"
                }
            ],
            assessmentItems:[],
            studentHeaders:[
                {
                    text : "Họ tên",
                    value : "ho_ten"
                },
                {
                    text : "Tên tiếng anh",
                    value : "ten_en"
                },
                {
                    text : "Mã học sinh",
                    value : "the_sv"
                },
                {
                    text : "Ngày sinh",
                    value : "ngay_sinh"
                }
            ],
            studentItems:[],
            dlgSelectHS : false,
            selectedStudent : []
        }
    },
    components:{
        ConfirmDialog
    },
    computed:{
        ...mapState("assessment", {
            lop: "lop",
            khoahoc: "khoahoc"
        }),
    },
    created(){
        this.selectedCourse = this.khoahoc
        this.selectedClass = this.lop
        
        let postData = {
            command: "G_L_GV",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.classItems = res.data           
        })

        postData = {
            command: "G_KH",
            parameter:{
                khach_hang:auth.getUserName()
            }            
        }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.courses = res.data           
        })

        postData = {
                command: "G_ASSESSM",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id,
                    hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id
                }            
            }        

        api.postData("/System/ExecuteCommand",postData).then(res => {
            this.assessmentItems = res.data           
        })
    },
    methods:{
        classChange(){
            this.selectedCourse = {
                hp_khoahoc_id : this.selectedClass.hp_khoahoc_id,
                ma_khoahoc : this.selectedClass.ma_khoahoc,
                ten_khoahoc : this.selectedClass.ten_khoahoc
            }

            Store.dispatch("assessment/setLop", this.selectedClass)
            Store.dispatch("assessment/setKhoaHoc", this.selectedCourse)
        },
        loadData() {
            this.$refs.form.validate()

            if (this.valid == false) return

            let postData = {
                command: "G_ASSESSM",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id,
                    hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.assessmentItems = res.data           
            })
        },
        showDlgStudent(visible) {
            this.dlgSelectHS = visible
            this.selectedStudent = []

            if (visible == false) return

            let postData = {
                command: "GET_DMHS",
                parameter:{
                    hp_lop_id: this.selectedClass.hp_lop_id                    
                }            
            }        

            api.postData("/System/ExecuteCommand",postData).then(res => {
                this.studentItems = res.data           
            })
        },
        add() {
            this.$refs.form.validate()

            if (this.valid == true) {
                this.showDlgStudent(true)
            }            
        },
        nextToAdd() {
            if (this.selectedStudent.length == 0) {
                alert("Bạn chưa chọn học sinh")
                return
            }

            let hocsinh = this.selectedStudent[0]
            

            Store.dispatch("assessment/setHocSinh", hocsinh)
            Store.dispatch("assessment/setMode", 1)

            let now = new Date()

            let assessItem = {
                hp_assessment_id: null,
                ngay: now,
                ngay1: now.toLocaleDateString("vi-VN"),
                hp_dmhs_id: hocsinh.hp_dmhs_id,
                ho_ten: hocsinh.ho_ten,
                hp_lop_id: this.selectedClass.hp_lop_id,
                ma_lop: this.selectedClass.ma_lop,
                ten_lop: this.selectedClass.ten_lop,
                hp_khoahoc_id: this.selectedCourse.hp_khoahoc_id,
                ma_khoahoc : this.selectedCourse.ma_khoahoc,
                ten_khoahoc : this.selectedCourse.ten_khoahoc,
                comment: "",
                passed : ""
            }

            Store.dispatch("assessment/setAssessItem",assessItem)

            this.$router.push({
                name: "AddAssessment"                
            });
        },
        edit(item) {

            let hocsinh = {
                hp_dmhs_id : item.hp_dmhs_id,
                ho_ten : item.ho_ten
            }

            Store.dispatch("assessment/setHocSinh", hocsinh);
            Store.dispatch("assessment/setMode", 2);

            Store.dispatch("assessment/setAssessItem",item)

            this.$router.push({
                name: "EditAssessment" ,
                params: { id: item.hp_assessment_id }               
            });
        },
        remove(item) {
            this.dlgConfirm = true
            this.dlgItem = item
        },
        onCancel() {
            this.dlgConfirm = false
        },
        onConfirm(item) {
            
            let postData = {
                id: item.hp_assessment_id           
            }        

            api.postData("/Teacher/DeleteAssessment",postData).then(res => {
                
                if (res.data.hasOwnProperty("error")){
                    alert(res.data.error)
                }
                else {
                    let idx = -1

                    for(let i=0;i<this.assessmentItems.length;i++){
                        if (this.assessmentItems[i].hp_assessment_id == item.hp_assessment_id){
                            idx = i
                            break
                        }
                    }

                    if (idx != -1) {
                        this.assessmentItems.splice(idx,1)
                    }
                }

                this.dlgConfirm = false
            })
        }
    }
}
</script>